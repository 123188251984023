import { Suspense, lazy } from 'react'

const New = lazy(() => import('../../pages/New'))
const ContactUs = lazy(() => import('../../pages/ContactUs'))
const PrivacyPolicy = lazy(() => import('../../pages/PrivacyPolicy'))
const AboutUs = lazy(() => import('../../pages/AboutUs'))
const LeadGen = lazy(() => import('../../pages/LeadGen'))
const BookACall = lazy(() => import('../../pages/BookACall'))
const Blogs = lazy(() => import('../../pages/Blogs'))
const Blog = lazy(() => import('../../pages/Blog'))
const Category = lazy(() => import('../../pages/Category'))
const PageNotFound = lazy(() => import('../../pages/PageNotFound'))

const PublicPaths = [
  {
    path: '/',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <New />
      </Suspense>
    ),
  },
  {
    path: '/contact',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <ContactUs />
      </Suspense>
    ),
  },
  {
    path: '/about',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <AboutUs />
      </Suspense>
    ),
  },
  {
    path: '/privacy',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: '/leadgen',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <LeadGen />
      </Suspense>
    ),
  },
  {
    path: '/book-a-call',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <BookACall />
      </Suspense>
    ),
  },
  {
    path: '/blog',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Blogs />
      </Suspense>
    ),
  },
  {
    path: '/blog/:slug',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Blog />
      </Suspense>
    ),
  },
  {
    path: '/blog/category/:slug',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Category />
      </Suspense>
    ),
  },
  {
    path: '*',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <PageNotFound />
      </Suspense>
    ),
  },
]
export default PublicPaths
