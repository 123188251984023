import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import React, { useEffect, useRef, useState } from 'react'
import { notification } from 'antd'
import { useAuth } from '../../hooks/useAppContext'
import { Button } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Frame from '../../assets/images/Frame.png'
import './styles.scss'
import axios, { all } from 'axios'
import { useNavigate } from 'react-router-dom'
import ClientsCarasoul from '../../components/ClientsCarasoul'
import BookACall from '../../components/BookACall'
import PageLoader from '../../components/PageLoader'

const CalculatorRow = () => {
  const { login } = useAuth()
  const navigate = useNavigate()
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/leadgens/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            image: {
              fields: ['name', 'url'],
            },
            formText: {
              populate: ['title'],
            },
            qualities: {
              populate: ['title'],
            },
            bookACall: {
              populate: ['title'],
            },
            features: {
              populate: ['image'],
            },
          },
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    getPageData()
  }, [])
  const authenticate = async () => {
    try {
      const response = await axios({
        url: 'https://api.podio.com/oauth/token/v2',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          grant_type: 'password',
          username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
          password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
          client_id: process.env.REACT_APP_USER_INFO_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
          client_secret: process.env.REACT_APP_USER_INFO_APP_CLIENT_SECRET,
        },
      })
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.log('Error', error.response.data)
      return
    }
  }
  const storeDataInPodio = async (accessToken, values) => {
    let session = {
      name: '',
      email: '',
    }
    try {
      const appId = process.env.REACT_APP_USER_INFO_APP_ID
      const url = `https://api.podio.com/item/app/${appId}`
      const payload = {
        fields: {
          name: values?.name ?? '',
          email: [{ type: 'work', value: values?.email }],
        },
      }
      const allData = await axios.get(url, {
        headers: {
          Authorization: `OAuth2 ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      if (allData?.data?.items?.length > 0) {
        allData.data.items.map((item, index) => {
          if (item?.fields?.length > 0) {
            item?.fields?.map((field) => {
              if (field?.external_id == 'email' && field?.values?.length) {
                if (field.values[0]?.value == values?.email) {
                  session.email = values?.email
                }
              }
            })
          }
        })
      }

      if (!session.email) {
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `OAuth2 ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        session.email = values.email
      }
      return session
    } catch (error) {
      console.error('Error storing data in Podio:', error)
      return
    }
  }
  const handleSubmitForm = async (e) => {
    setUserLoading(true)
    try {
      e.preventDefault()
      let values = {}
      if (e?.target?.name?.value) {
        values.name = e.target.name.value
      }
      if (e?.target?.email?.value) {
        values.email = e.target.email.value
      }
      if (values.email) {
        const authorization = await authenticate()
        if (!authorization) {
          setUserLoading(false)
          return
        }
        const data = await storeDataInPodio(authorization?.access_token, values)
        if (!data?.email) {
          setUserLoading(false)
          return
        } else {
          login(data.email)
          notification.success({
            message: 'Success',
            description: 'Information sent successfully',
          })
          navigate('/thankyou')
        }
      } else {
        notification.error({
          message: 'Failed',
          description: 'Email is required',
        })
      }
      setUserLoading(false)
    } catch (error) {
      setUserLoading(false)
    }
  }

  const createUser = async (data) => {
    try {
      if (data?.email) {
        let config = {
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/user`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        }
        const res = await axios.request(config)
        let state = {}
        if ((res?.status == 201 || res?.status == 208) && res?.data?.id && res.data.email) {
          state.id = res.data.id
          state.email = res.data.email
          if (res.data.name) {
            state.name = res.data.name
          }
          if (res.status == 201) {
            navigate('/thankyou', { state })
          } else {
            navigate('/property', { state })
          }
        }
        console.log('response', res)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('error in creating user')
    }
  }
  const handleBookACall = () => {
    if (pageData?.bookACall?.targetBlank) {
      window.open('https://calendly.com/bluhatbookkeeping/30mincall', '_blank')
    } else {
      navigate(pageData?.bookACall?.path)
    }
  }

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className='w-full py-0 sm:py-16 lg:py-28 mx-auto'>
          <div className='flex flex-col lg:flex-row w-full mx-auto'>
            <div className='w-full lg:w-1/2 py-0 lg:py-0 flex flex-col items-start'>
              {pageData?.title && (
                <h1 className='font-bold text-2xl lg:text-5xl pr-4 text-gray-900 mb-5'>
                  {pageData?.title}
                </h1>
              )}
              {pageData?.description && (
                <p className='text-gray-600 mb-5 lg:mb-8 text-lg lg:text-xl'>
                  {pageData?.description}
                </p>
              )}
            </div>

            <div className='flex items-center justify-center'>
              <div className='w-full lg:w-2/3 mx-auto bg-white p-8 rounded-lg shadow-xl'>
                <div className='text-center'>
                  {pageData?.formText?.title && (
                    <h2 className='text-gray-900 mb-6 lg:text-xl font-medium '>
                      {pageData?.formText?.title}
                    </h2>
                  )}
                  {pageData?.formText?.description && (
                    <p className='text-gray-600 mb-6 font-normal lg:text-base sm:text-sm'>
                      {pageData?.formText?.description}
                    </p>
                  )}
                  <form className='space-y-10' onSubmit={handleSubmitForm}>
                    <input
                      disabled={loading || userLoading}
                      name='name'
                      type='text'
                      placeholder='Your First Name'
                      className='w-full px-6 py-2 border-b border-gray-800 focus:outline-none focus:border-indigo-500'
                    />
                    <input
                      disabled={loading || userLoading}
                      name='email'
                      type='email'
                      placeholder='Email Address'
                      required={true}
                      className='w-full px-6 py-2 border-b border-gray-800 focus:outline-none focus:border-indigo-500'
                    />
                    <button
                      disabled={loading || userLoading}
                      type='submit'
                      className='w-full bg-black text-white py-2 lg:w-[389px]'
                    >
                      {loading || userLoading ? (
                        <span>...Loading</span>
                      ) : (
                        <span>{pageData?.formText?.btnText ?? 'Submit'}</span>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full flex justify-center py-10 mt-10 lg:px-8'>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div className='grid '>
                {pageData?.features?.length > 0 &&
                  pageData?.features?.map((feature) => {
                    return (
                      <div className='grid grid-cols-[auto,1fr]'>
                        {(feature.image?.data?.attributes?.url ||
                          feature?.image?.data?.attributes?.alternativeText) && (
                          <img
                            src={feature.image.data.attributes.url}
                            alt={feature.image.data.attributes.alternativeText ?? 'Image'}
                            className='w-8 h-10 mt-1'
                          />
                        )}
                        <div className='ml-4'>
                          <h2 className='text-lg lg:text-2xl font-bold'>{feature?.title}</h2>
                          {feature?.description && (
                            <div className='markdown text-gray-600 text-base lg:text-xl'>
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                {feature?.description}
                              </ReactMarkdown>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
              {(pageData?.image?.data?.attributes?.url ||
                pageData?.image?.data?.attributes?.alternativeText) && (
                <div className='items-center w-full'>
                  <div>
                    <img
                      src={pageData.image.data.attributes.url}
                      alt={pageData.image.data.attributes.alternativeText ?? 'Image'}
                      className='h-auto'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='w-full flex flex-col items-center py-4 gap-y-4 sm:flex-row sm:justify-center sm:gap-x-6 lg:gap-x-8'>
            {pageData?.qualities?.length > 0 &&
              pageData?.qualities?.map((quality) => {
                return (
                  <div className='border rounded-sm shadow-lg h-10 w-44 flex justify-center items-center bg-white'>
                    <img src={Frame} alt='' className='w-4 h-4 mr-2' />
                    {quality?.title}
                  </div>
                )
              })}
          </div>
          <ClientsCarasoul />

          {pageData?.bookACall?.path && (
            <div className='flex justify-center items-center py-10 my-10'>
              <BookACall
                title={pageData?.bookACall?.title}
                description={pageData?.bookACall?.description}
                btnText={pageData?.bookACall?.btnText}
                handleBtnClick={handleBookACall}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CalculatorRow
