import React from 'react'

const HeroSection = ({ data }) => {
  return (
    <div className='w-full py-0 md:py-32 mx-0 px-0 my-0 lg:p-3'>
      <div className='flex flex-col md:flex-row w-full md:w-[85%] mx-auto px-0'>
        <div className='w-full md:w-1/2 flex flex-col justify-center mb-4 md:mb-0'>
          {data?.publishedAt != null && (
            <div className='w-[80%] mx-auto md:mx-0'>
              {data?.title && (
                <h1 className='text-4xl md:text-[72px] leading-tight md:leading-[80px] tracking-tight md:tracking-[-4%] font-bold text-center md:text-left'>
                  {data.title}
                </h1>
              )}
              {data?.description && (
                <p className='mt-4 text-lg md:text-[22px] text-[#475467] text-center md:text-left'>
                  {data.description}
                </p>
              )}
            </div>
          )}
          <div className='flex flex-row mt-4 justify-center md:justify-start'>
            <p className='ml-3 items-center mt-2'></p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HeroSection
