import Layout from '../../components/Layout'
import { Route, Routes } from 'react-router-dom'
import PrivateRoutes from '../../components/PrivateRoutes'
import PrivatePaths from '../ProtectedRoutes'
import PublicPaths from '../PublicRoutes'
const RoutesHOC = () => {
  return (
    <>
      <Routes>
        <Route>
          {PublicPaths.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Layout>
                  <route.Content />
                </Layout>
              }
            />
          ))}
          <Route element={<PrivateRoutes />}>
            {PrivatePaths.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Layout>
                    <route.Content />
                  </Layout>
                }
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </>
  )
}
export default RoutesHOC
