import { useEffect, useState } from 'react'
import HeroSection from '../../components/HeroSection'
import PropertyForm from '../../components/PropertyForm'
import axios from 'axios'
import ScrollingCard from '../../components/ScrollingCard'
import { showErrorNotification } from '../../notifications/Error/notification'
import { useLocation, useNavigate } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'

const Home = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [pageData, setPageData] = useState()
  const [initialLoading, setInitialLoading] = useState(false)
  const [userId, setUserId] = useState(null)
  const [property, setProperty] = useState({})
  const [scenario, setScenario] = useState({})
  const [values, setValues] = useState({})
  const [disableRunScenario, setDisableRunScenario] = useState(true)
  const [imgUrl, setImgUrl] = useState(null)
  const [imgId, setImgId] = useState(null)
  const [imgLoading, setImgLoading] = useState(null)
  const [loading, setLoading] = useState(false)
  const createPropertyAndScenario = async (data) => {
    setLoading(true)
    let tempProperty = {}
    let tempScenario = {}
    try {
      if (
        data?.property?.address &&
        data?.property?.city &&
        data?.property?.state &&
        data?.property?.zipcode
      ) {
        tempProperty = { ...data?.property }
        if (imgUrl && imgId) {
          tempProperty.file_id = imgId
        }

        let config = {
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/property`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(tempProperty),
        }

        const res = await axios.request(config)
        if (res?.status == 201 && res.data?.data?.id) {
          tempProperty = res.data.data
        }
        if (tempProperty?.id && data?.scenario?.hold_startDate && data?.scenario?.hold_payOffDate) {
          tempScenario = {
            ...data?.scenario,
            property_id: tempProperty.id,
          }
          let configScenario = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/scenario`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify(tempScenario),
          }

          const resScenario = axios.request(configScenario)
          if (resScenario?.status == 201 && resScenario?.data?.data?.id) {
            tempScenario = resScenario?.data?.data
          }
        }
      } else {
        showErrorNotification('Error while creating Property', 'Required Information is missing')
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }

    return { propertyRes: tempProperty, scenarioRes: tempScenario }
  }
  const getPageData = async () => {
    try {
      setInitialLoading(true)
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fix-and-flips/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        console.log('done it ')
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error')
    }

    setInitialLoading(false)
  }
  useEffect(() => {
    getPageData()
    if (location?.state?.id) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setUserId(location.state.id)
      window.history.replaceState({}, '')
    } else {
      // navigate('/leadgen')
    }
  }, [location])
  return (
    <div>
      {initialLoading ? (
        <PageLoader />
      ) : (
        <div className='relative'>
          <HeroSection data={pageData} />
          <PropertyForm
            property={property}
            setProperty={setProperty}
            scenario={scenario}
            setScenario={setScenario}
            createPropertyAndScenario={createPropertyAndScenario}
            setValues={setValues}
            disableRunScenario={disableRunScenario}
            setDisableRunScenario={setDisableRunScenario}
            imgUrl={imgUrl}
            setImgUrl={setImgUrl}
            imgId={imgId}
            setImgId={setImgId}
            imgLoading={imgLoading}
            setImgLoading={setImgLoading}
            loading={loading}
          />
          <ScrollingCard
            values={values}
            disableFields={true}
            scenarios={[]}
            property={property}
            activeScenario={0}
            onChange={() => {}}
            sharePdf={true}
            imgUrl={''}
            imgId={''}
          />
        </div>
      )}
    </div>
  )
}
export default Home
