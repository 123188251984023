import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Collapse } from 'antd'
import './styles.scss'
import ClosedArrow from '../../assets/svg/ClosedArrow'
import OpenArrow from '../../assets/svg/OpenArrow'
import { useEffect, useState } from 'react'
import axios from 'axios'
import PageLoader from '../../components/PageLoader'
const Icon = ({ isActive }) => {
  return (
    <div
      className={`${isActive ? 'bg-[#6941C6]' : 'iconShadow'} flex justify-center items-center w-[50px] h-[50px] rounded-[25px]`}
    >
      {isActive ? (
        <span className='pl-[1px]'>
          <OpenArrow />
        </span>
      ) : (
        <span className='pl-[2px]'>
          <ClosedArrow />
        </span>
      )}
    </div>
  )
}

const PrivacyPolicy = () => {
  const [pageData, setPageData] = useState({})
  const [faqs, setFaqs] = useState([])
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/privacys/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs ', error)
    }
    return true
  }
  const getFaqsData = async () => {
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/faqs?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.length > 0) {
        setFaqs(res?.data?.data)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    return true
  }
  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setLoading(true)
      await getPageData()
      await getFaqsData()
      setLoading(false)
    }

    fetchData()
  }, [])
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <div className='privary-policy'>
          {pageData?.title && (
            <>
              <div className='flex justify-between items-center py-0 lg:py-5 w-full mx-auto flex-wrap md:py-0'>
                <div className='py-0 lg:py-[90px] w-full'>
                  {pageData?.subText && (
                    <h1 className='text-center text-[#6941C6] font-semibold text-xl'>
                      {pageData?.subText}
                    </h1>
                  )}
                  <h1 className='text-center text-[#101828] font-semibold text-2xl lg:text-5xl mt-[20px]'>
                    {pageData?.title}
                  </h1>
                  {pageData?.text && (
                    <p className='text-center text-[#475467] font-normal text-lg lg:text-xl mt-[30px] mx-auto w-full md:w-1/2'>
                      {pageData?.text}
                    </p>
                  )}
                </div>
              </div>
              {/* Hero Section Start */}
              <div className='flex justify-between items-center py-2 lg:py-0 w-full mx-auto flex-wrap md:py-0'>
                <div className='pt-0 lg:pb-[90px]'>
                  <div className='flex justify-between flex-col md:flex-row flex-wrap items-center'>
                    {(pageData?.image?.data?.attributes?.url ||
                      pageData?.image?.data?.attributes?.alternativeText) && (
                      <div>
                        <img
                          src={pageData.image.data.attributes.url}
                          alt={pageData.image.data.attributes.alternativeText ?? 'Image'}
                        />
                      </div>
                    )}
                    {pageData?.description && (
                      <div className='2xl:w-[55%] xl:w-[45%] md:w-[35%] w-full h-full'>
                        <p className='text-[#475467] font-normal text-base lg:text-xl mt-[15px] 2xl:mt-[25px] text-justify'>
                          {pageData?.description}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Hero Section End */}
          {faqs?.length > 0 && (
            <div className='flex justify-between items-center py-5 w-full mx-auto flex-wrap md:py-0'>
              <div className='bg-[#F5F5F5] rounded-2xl w-full py-[50px] px-[15px] md:p-[50px] mb-[55px]'>
                <h1 className='text-center text-[#101828] font-semibold text-4xl mb-[25px]'>
                  F.A.Qs
                </h1>

                <Collapse
                  accordion
                  items={faqs?.map((faq, index) => {
                    const obj = {}
                    obj.key = index + 1
                    obj.label = faq?.attributes?.question
                    obj.children = (
                      <div className='markdown text-[#475467] font-normal text-sm lg:text-[17px]'>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {faq?.attributes?.answer}
                        </ReactMarkdown>
                      </div>
                    )
                    return obj
                  })}
                  expandIconPosition={'end'}
                  bordered={false}
                  expandIcon={({ isActive }) => <Icon isActive={isActive} />}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default PrivacyPolicy
