import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const BlogCard = ({ item, isLast, newLimit, srNo, navigateToCategory }) => {
  const navigate = useNavigate()
  const getCroppedImageStyle = (imageSrc) => {
    const aspectRatio = 16 / 9
    const modifiedSrc = `${imageSrc}`
    const width = 100 // Set a standard width for the cropped image
    const height = 400 / aspectRatio // Calculate height based on aspect ratio
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: `${width}%`,
      height: `${height}px`,
    }
  }
  const listRef = useRef()

  useEffect(() => {
    if (!listRef?.current) return

    const observer = new IntersectionObserver(([entry]) => {
      if (isLast && entry.isIntersecting) {
        newLimit()
        observer.unobserve(entry.target)
      }
    })

    observer.observe(listRef.current)
  }, [isLast])
  const navigateToBlog = (slug) => {
    navigate(`/blog/${slug}`)
  }
  return (
    <div
      className={`${srNo % 3 != 0 ? 'mr-0 lg:mr-[20px]' : ''} w-full lg:w-[32%] h-full p-[15px] lg:p-[20px] mt-[15px] lg:mt-[20px] bg-white card-shadow`}
      key={item?.attributes?.slug}
      ref={listRef}
    >
      <div className='w-full'>
        <div
          className='flex mx-auto'
          style={getCroppedImageStyle(item.attributes?.cover?.data?.attributes?.url)}
        ></div>
      </div>
      <div className='max-h-[32px] lg:max-h-[46px] h-[32px] lg:h-[46px] mt-[8px] lg:mt-[10px] flex justify-start items-center flex-wrap overflow-hidden'>
        {item?.attributes?.categories?.data?.length > 0 &&
          item.attributes.categories.data?.map((category) => {
            return (
              <span
                onClick={() => {
                  navigateToCategory(category?.attributes?.slug)
                }}
                key={category?.attributes?.slug}
                className='bg-black text-white px-2 lg:px-4 py-1 lg:py-2 rounded-md lg:rounded-[10px] mr-1 lg:mr-2 font-semibold text-base lg:text-xl cursor-pointer'
              >
                {category?.attributes?.name}
              </span>
            )
          })}
      </div>
      <div className='max-h-[55px] lg:max-h-[72px] h-[55px] lg:h-[72px] mt-[8px] lg:mt-[10px]'>
        <h1 className='card-title font-semibold text-lg lg:text-2xl text-[#101828]'>
          {item?.attributes?.title}
        </h1>
      </div>

      <div className='flex items-center font-semibold text-[15px] text-[#3C87F1] max-h-[23px] h-[23px] mt-[3px] lg:mt-[10px]'>
        {item?.attributes?.author?.data?.attributes?.name && (
          <>
            <span className='mr-1 lg:mr-2'>By</span>{' '}
            <h1 className='author-title'>{item?.attributes?.author?.data?.attributes?.name}</h1>
          </>
        )}
      </div>
      <div className='description max-h-[80px] lg:max-h-[97px] h-[80px] lg:h-[97px] mt-[5px] lg:mt-[10px]'>
        {item?.attributes?.description && (
          <p className='font-semibold text-[#667085] text-sm lg:text-base'>
            {item?.attributes?.description}
          </p>
        )}
      </div>
      <div className='flex justify-center mt-[10px]'>
        <button
          onClick={() => {
            navigateToBlog(item?.attributes?.slug)
          }}
          className=' w-full bg-[#3C87F1] font-semibold text-lg lg:text-2xl text-white h-[35px] lg:h-[55px] flex items-center justify-center'
        >
          Read More
        </button>
      </div>
    </div>
  )
}
export default BlogCard
