import { Suspense, lazy } from 'react'

const Home = lazy(() => import('../../pages/Home'))
const Property = lazy(() => import('../../pages/Property'))
const PerformingNotes = lazy(() => import('../../pages/PerformingNotes'))
const CreatedPerformingNotes = lazy(() => import('../../pages/CreatedPerformingNotes'))
const Thankyoupage = lazy(() => import('../../pages/Thankyoupage'))

const PrivatePaths = [
  {
    path: '/property',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: '/property/:id',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Property />
      </Suspense>
    ),
  },
  {
    path: '/performingNote',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <PerformingNotes />
      </Suspense>
    ),
  },
  {
    path: '/performingNote/:id',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <CreatedPerformingNotes />
      </Suspense>
    ),
  },
  {
    path: '/thankyou',
    Content: () => (
      <Suspense fallback={<div></div>}>
        <Thankyoupage />
      </Suspense>
    ),
  },
]
export default PrivatePaths
