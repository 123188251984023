import { notification } from 'antd'

export const showErrorNotification = (title = 'Error', message = '') => {
  notification.error({
    message: title,
    description: message,
    onClick: () => {
      console.log('Notification Clicked!')
    },
  })
}
