import React from 'react'

function PhoneIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#1D2939'
        d='M17.5 13.683v2.947a.833.833 0 01-.775.832c-.364.025-.662.038-.892.038C8.47 17.5 2.5 11.53 2.5 4.167c0-.23.013-.528.038-.892A.833.833 0 013.37 2.5h2.947a.417.417 0 01.415.375c.019.192.036.344.053.46.166 1.156.505 2.28 1.007 3.334a.38.38 0 01-.123.473L5.871 8.427a10.872 10.872 0 005.703 5.703l1.284-1.795a.385.385 0 01.477-.124c1.054.5 2.178.84 3.333 1.004.116.017.269.035.459.053a.417.417 0 01.374.415H17.5z'
      ></path>
    </svg>
  )
}

export default PhoneIcon
