import { Form, Input } from 'antd'
const InputField = ({ objName, fieldName, rules, validationTrigger, placeholder }) => {
  return (
    <Form.Item
      name={[objName, fieldName]}
      rules={rules}
      validateTrigger={validationTrigger[`${objName}`][`${fieldName}`]}
      className='col-span-3'
    >
      <Input placeholder={placeholder} className='w-full' />
    </Form.Item>
  )
}
export default InputField
