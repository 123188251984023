import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect, useState } from 'react'
import BookACall from '../../components/BookACall'
import './styles.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'

const AboutUs = () => {
  const navigate = useNavigate()
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/abouts/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            Story: {
              populate: ['image'],
            },
            Stats: {
              populate: ['title'],
            },
            BookACall: {
              populate: ['title'],
            },
          },
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  const handleBookACall = () => {
    if (pageData?.BookACall?.targetBlank) {
      window.open('https://calendly.com/bluhatbookkeeping/30mincall', '_blank')
    } else {
      navigate(pageData?.BookACall?.path)
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    getPageData()
  }, [])
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <div className=''>
          <div className='flex justify-between items-center py-3 lg:py-5 w-full mx-auto flex-wrap md:py-0'>
            <div className='lg:py-[90px] py-0 w-full'>
              {pageData?.subText && (
                <h1 className='text-center text-[#6941C6] font-semibold text-lg lg:text-xl'>
                  {pageData.subText}
                </h1>
              )}
              {pageData?.title && (
                <h1 className='text-center text-[#101828] font-semibold text-2xl lg:text-5xl mt-[10px] lg:mt-[20px]'>
                  {pageData.title}
                </h1>
              )}
              {pageData?.description && (
                <p className='text-center text-[#475467] font-normal text-base lg:text-xl mt-[15px] lg:mt-[30px] mx-auto w-full md:w-[65%]'>
                  {pageData.description}
                </p>
              )}
            </div>
          </div>
          {/* Hero Section Start */}
          <div className='flex justify-between items-center w-full mx-auto flex-wrap md:py-0'>
            <div className='lg:py-[90px] py-[30px]'>
              <div className='flex justify-between flex-col md:flex-row flex-wrap items-start'>
                <div className='2xl:w-[53%] xl:w-[52%] lg:w-[40%] w-full h-full'>
                  {pageData?.Story?.subText && (
                    <h1 className='text-start text-[#7F56D9] font-semibold text-lg lg:text-xl'>
                      {pageData?.Story?.subText}
                    </h1>
                  )}
                  {pageData?.Story?.title && (
                    <h1 className='text-start text-[#101828] font-semibold text-[22px] lg:text-4xl mt-4'>
                      {pageData?.Story?.title}
                    </h1>
                  )}
                  {pageData?.Story?.description && (
                    <div className='markdown font-normal text-base lg:text-xl text-[#475467] text-justify pt-[15px]'>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {pageData?.Story?.description}
                      </ReactMarkdown>
                    </div>
                  )}
                </div>
                {(pageData?.Story?.image?.data?.attributes?.url ||
                  pageData?.Story?.image?.data?.attributes?.alternativeText) && (
                  <div className='max-w-full 2xl:max-w-[43%] xl:max-w-[43%] lg:max-w-[55%] mt-[30px] lg:mt-0 '>
                    <img
                      src={pageData.Story.image.data.attributes.url}
                      alt={pageData.Story.image.data.attributes.alternativeText ?? 'Image'}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Hero Section End */}
          <div className='flex justify-between items-center w-full mx-auto flex-wrap md:py-0'>
            <div className=''>
              <div className='flex justify-between flex-col md:flex-row flex-wrap items-center'>
                {pageData?.para && (
                  <div className='w-full h-full markdown text-[#475467] font-normal text-base lg:text-xl mt-[15px] 2xl:mt-[20px] text-justify'>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageData.para}</ReactMarkdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Project Section Start */}
          {pageData?.Stats?.length > 0 && (
            <div
              className={`flex bg-[#C9E3FF] h-full w-full mt-10 mb-0 lg:mb-[180px] ${pageData?.statsTitle ? 'min-h-[300px]' : ''}`}
            >
              <div
                className={`relative flex justify-between items-center py-5 w-full mx-auto flex-wrap md:py-0 ${pageData?.statsTitle ? 'min-h-[300px]' : ''}`}
              >
                <div className='flex flex-col items-center w-full'>
                  {pageData?.statsTitle && (
                    <h1 className='text-center text-2xl lg:text-5xl text-[#101828] font-semibold'>
                      {pageData?.statsTitle}
                    </h1>
                  )}
                  <div className='block lg:absolute lg:top-[80%] lg:right-[10%] lg:left-[10%] lg:w-auto w-[60%]'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center w-full'>
                      {pageData?.Stats?.map((stat) => {
                        return (
                          <div className='lg:w-[22%] mt-[20px] lg:mt-0 flex flex-col bg-white px-[0px] py-[20px] lg:py-[40px] sm-box-shadow'>
                            <h1 className='font-semibold text-[#6941C6] text-lg lg:text-[33px] text-center'>
                              {stat?.number}+
                            </h1>
                            <h1 className='font-semibold text-[#101828] text-sm lg:text-base text-center'>
                              {stat?.title}
                            </h1>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Project Section End */}
          {pageData?.BookACall?.id && (
            <div className='flex justify-center items-center py-10 mb-10'>
              <BookACall
                title={pageData?.BookACall?.title}
                description={pageData?.BookACall?.description}
                btnText={pageData?.BookACall?.btnText}
                handleBtnClick={handleBookACall}
                bgColor={'bg-[#F5F5F5]'}
                shadowClass={'customeShadow'}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AboutUs
