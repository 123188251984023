import React, { useEffect, useState } from 'react'
import PdfSharingModal from '../PdfSharingModal'
import { Button, Select } from 'antd'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
const ScrollingCard = ({
  values,
  disableFields,
  scenarios,
  property,
  activeScenario,
  onChange,
  sharePdf,
  imgUrl,
  imgId,
}) => {
  const params = useParams()
  const [reportId, setReportId] = useState(false)
  const { Option } = Select
  const [open, setOpen] = useState(false)
  const [scenarios1, setScenarios1] = useState([])

  const showModal = () => {
    setOpen(true)
  }

  const formatNumber = (number) => {
    if (number === null || number === undefined) {
      return '00.00%'
    }
    return (Math.round(number * 100) / 100).toFixed(2) + '%'
  }
  useEffect(() => {
    if (params?.id) {
      setReportId(params.id)
    }
  }, [params])

  useEffect(() => {
    // Make sure scenarios[0] exists before accessing its properties
    if (scenarios[0]) {
      if (scenarios[0].hold_startDate) {
        let startDate = dayjs(scenarios[0].hold_startDate)
        let startFormattedDate = startDate.format('YYYY-MM-DD') // Changed 'D' to 'DD'
        console.log(startFormattedDate)
        scenarios[0].hold_startDate = startFormattedDate
      }

      if (scenarios[0].hold_payOffDate) {
        let endDate = dayjs(scenarios[0].hold_payOffDate)
        let endFormattedDate = endDate.format('YYYY-MM-DD') // Changed 'D' to 'DD'
        console.log(endFormattedDate)
        scenarios[0].hold_payOffDate = endFormattedDate
      }
    }

    // Make sure scenarios[1] exists before accessing its properties
    if (scenarios[1]) {
      if (scenarios[1].hold_startDate) {
        let startDate1 = dayjs(scenarios[1].hold_startDate)
        let startFormattedDate1 = startDate1.format('YYYY-MM-DD')
        console.log(startFormattedDate1)
        scenarios[1].hold_startDate = startFormattedDate1
      }

      if (scenarios[1].hold_payOffDate) {
        let endDate1 = dayjs(scenarios[1].hold_payOffDate)
        let endFormattedDate1 = endDate1.format('YYYY-MM-DD')
        console.log(endFormattedDate1)
        scenarios[1].hold_payOffDate = endFormattedDate1
      }
    }

    // Make sure scenarios[2] exists before accessing its properties
    if (scenarios[2]) {
      if (scenarios[2].hold_startDate) {
        let startDate2 = dayjs(scenarios[2].hold_startDate)
        let startFormattedDate2 = startDate2.format('YYYY-MM-DD')
        console.log(startFormattedDate2)
        scenarios[2].hold_startDate = startFormattedDate2
      }

      if (scenarios[2].hold_payOffDate) {
        let endDate2 = dayjs(scenarios[2].hold_payOffDate)
        let endFormattedDate2 = endDate2.format('YYYY-MM-DD')
        console.log(endFormattedDate2)
        scenarios[2].hold_payOffDate = endFormattedDate2
      }
    }
    setScenarios1(scenarios)
  }, [scenarios])

  return (
    <div className='absolute top-[331px] left-0 right-0 md:top-0 md:bottom-[11px] md:left-[60%] z-[400]'>
      <div className='w-full lg:w-[530px] sticky top-[40px] left-[40px]'>
        <div className='bg-white p-3 lg:rounded-xl sm:rounded-[0px] lg:shadow-xl sm:shadow-none'>
          <div className='flex justify-between items-center'>
            <div className='text-xl font-semibold'>Profit</div>
            <div className='text-end w-[full'>
              <Select
                placeholder='Scenario'
                disabled={disableFields || scenarios?.length == 0}
                className='w-[100px]'
                onChange={(value) => {
                  onChange(value)
                }}
              >
                {scenarios.map((item, index) => {
                  return (
                    <Option value={item?.id ?? 0} key={item?.id ?? 0}>
                      Scenario {index + 1}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className='text-5xl font-bold text-center mt-4' style={{ color: '#1D2939' }}>
            $
            {values?.profit?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) ?? '000,000'}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 text-sm mt-4'>
            <div className='text-center mb-4 md:mb-0'>
              <h1 className='font-semibold text-base'>Expenses</h1>
              <div className='flex justify-between p-1'>
                <div className='text-left text-sm font-semibold'>
                  <p className='mt-2'>Purchase price</p>
                  <p className='mt-2'>Purchase cost</p>
                  <p className='mt-2'>Rehab cost</p>
                  <p className='mt-2'>Holding cost</p>
                  <p className='mt-2'>Loan amount</p>
                  <p className='mt-2'>Loan origination cost</p>
                  {/* <p className='mt-2'>Financing cost</p>
                  <p className='mt-2'>Selling cost</p>
                  <p className='mt-2'>Profit</p> */}
                  <p className='mt-2'>Out of pocket cost</p>
                </div>
                <div className='text-sm font-normal'>
                  <div className='flex items-center justify-end mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.purchase_purchasePrice?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end  mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.purchase_costs?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end  mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.rehab_costs?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.holding_costs?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end  mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.financing_loanAmount?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end  mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.loan_origin_amount?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  {/* <div className='flex items-center justify-end mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.financing_costs?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end  mt-2'>
                    <span className='mr-1'>$</span>
                    <p>
                      {values?.selling_costs?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                  <div className='flex items-center justify-end'>
                    <span className='mr-1 mt-2'>$</span>
                    <p className='mt-2'>
                      {values?.profit?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div> */}
                  <div className='flex items-center justify-end'>
                    <span className='mr-1 mt-2'>$</span>
                    <p className='mt-2'>
                      {values?.out_of_pocket_cost?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) ?? '000,000'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center pl-5'>
              <h1 className='font-semibold text-base'>Deal Outlook</h1>
              <div className='flex justify-between p-1'>
                <div className='text-left text-sm font-semibold'>
                  <p className='mt-2'>ROI</p>
                  <p className='mt-2'>Annualised ROI</p>
                  <p className='mt-2'>IRR</p>
                </div>
                <div className='text-sm font-normal'>
                  <p className='mt-2'>{formatNumber(values.roi)}</p>
                  <p className='mt-2'>{formatNumber(values.annualised_roi)}</p>
                  <p className='mt-2'>{formatNumber(values.xirr)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='text-center mt-2  '>
              {sharePdf && (
                <>
                  <Button
                    className='h-10 font-medium bg-[#7F56D9] text-white'
                    block
                    onClick={showModal}
                    disabled={!reportId}
                  >
                    Share PDF With Others
                  </Button>
                  {scenarios1 && (
                    <PdfSharingModal
                      open={open}
                      setOpen={setOpen}
                      scenarios1={scenarios1}
                      property={property}
                      imgUrl={imgUrl}
                      imgId={imgId}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ScrollingCard
