import { Button } from 'antd'
import './styles.scss'
import Logo from '../../../assets/svg/Logo'
import { Link, useNavigate } from 'react-router-dom'
import FacebookIcon from '../../../assets/svg/FacebookIcon'
import TwitterIcon from '../../../assets/svg/TwitterIcon'
import GithubIcon from '../../../assets/svg/GithubIcon'
import { useAuth } from '../../../hooks/useAppContext'
const Footer = () => {
  const { isLoggedIn } = useAuth()
  const footer = {
    title: 'Not Sure Where to Start? Contact Us!',
    description:
      'Get a summary of what we have shipped during the last month, behind the scenes updates, and team picks.',
    links_1_heading: 'Product',
    links_1: [
      {
        title: 'Fix and Flip',
        path: '/property',
      },
      {
        title: 'Performing Note',
        path: '/performingNote',
      },
    ],
    links_2_heading: 'Company',
    links_2: [
      {
        title: 'About us',
        path: '/about',
      },
      {
        title: 'Contact us',
        path: '/contact',
      },
      {
        title: 'Privacy',
        path: '/privacy',
      },
      {
        title: 'Blogs',
        path: '/blog',
      },
    ],
    bookCallBtn: {
      path: '/book-a-call',
      targetBlank: false,
      btnText: 'Book A Call',
    },
  }
  const navigate = useNavigate()
  return (
    <div className='footer radial-gradient-top'>
      <div className='w-5/6 mx-auto py-3'>
        <div className='mt-10 sm:mt-20 lg:mt-28'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
            <div className=''>
              <div className=''>
                <h1 className='text-lg lg:text-xl font-semibold leading-tight text-[#2F2B43]'>
                  {footer?.title}
                </h1>
                <p className='mt-3 font-normal text-base leading-normal text-[#2F2B4399]'>
                  {footer?.description}
                </p>
              </div>
            </div>
            <div className='flex justify-end'>
              <Button
                onClick={() => {
                  if (footer?.bookCallBtn?.targetBlank) {
                    window.open(`${footer?.bookCallBtn?.path}`, '_blank')
                  } else {
                    navigate(`${footer?.bookCallBtn?.path}`)
                  }
                }}
                type='primary'
                className='w-full flex justify-center items-center h-10 px-3 lg:px-4 lg:w-auto text-white bg-[#7F56D9] font-medium text-base'
              >
                {footer?.bookCallBtn?.btnText}
              </Button>
            </div>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10 lg:mt-24'>
            <div className=''>
              <div className=''>
                <div className='flex items-end'>
                  <span
                    className='flex items-end cursor-pointer'
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <span className='mr-2 mb-1'>
                      <Logo />
                    </span>
                    <span className='font-bold text-lg lg:text-xl text-[#1D2939]'>
                      Blu Hat Bookkeeping
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className='pr-4 lg:pr-10'>
              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-5'>
                {footer?.links_1_heading && (
                  <div className='flex flex-col'>
                    <h1 className='font-medium text-[16px] leading-[-1%] text-[#2F2B4399] tracting-[24px]'>
                      {footer?.links_1_heading}
                    </h1>
                    {isLoggedIn &&
                      footer?.links_1?.length > 0 &&
                      footer?.links_1?.map((link) => {
                        return (
                          <Link
                            to={link?.path}
                            className='font-medium text-[16px] leadeing-[-1%] text-[#000000] tracting-[24px] mt-[5px]'
                          >
                            {link?.title}
                          </Link>
                        )
                      })}
                  </div>
                )}
                {footer?.links_2_heading && (
                  <div className='flex flex-col'>
                    <h1 className='font-medium text-[16px] leading-[-1%] text-[#2F2B4399] tracting-[24px]'>
                      {footer?.links_2_heading}
                    </h1>
                    {footer?.links_2?.length > 0 &&
                      footer?.links_2?.map((link) => {
                        return (
                          <Link
                            to={link?.path}
                            key={`footer${link.title}`}
                            className='font-medium text-[16px] leadeing-[-1%] text-[#000000] tracting-[24px] mt-[5px]'
                          >
                            {link?.title}
                          </Link>
                        )
                      })}
                  </div>
                )}

                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='bg-gray-200 text-grey'>
        <div className='w-5/6 mx-auto py-4'>
          <div className='container mx-auto flex flex-col sm:flex-row items-start sm:items-center justify-between'>
            <div className='flex flex-row flex-wrap items-center text-gray-600 mb-4 sm:mb-0'>
              <p className='text-xs sm:text-sm whitespace-nowrap'>Blu Hat Bookkeeping</p>
              <p className='text-xs sm:text-sm ml- sm:ml-4 whitespace-nowrap'>
                All rights reserved. © {new Date().getFullYear()}
              </p>
            </div>

            <div className='flex space-x-4'>
              <Link to='https://facebook.com' aria-label='Facebook'>
                <p>
                  <FacebookIcon />
                </p>
              </Link>
              <Link to='https://twitter.com' aria-label='Twitter'>
                <p>
                  <TwitterIcon />
                </p>
              </Link>
              <Link to='https://instagram.com' aria-label='Github'>
                <p>
                  <GithubIcon />
                </p>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Footer
