import React from 'react'

function CheckIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none' viewBox='0 0 29 26'>
      <path
        fill='#191A15'
        fillRule='evenodd'
        d='M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm-.165-9.704l5.043-5.2-1.436-1.392-4.353 4.488-2.56-2.437-1.378 1.449 3.277 3.12.718.683.69-.711z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default CheckIcon
