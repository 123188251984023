import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import CheckIcon from '../../assets/svg/CheckIcon'
import PlanModal from '../PlanModal'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ContactUsModal from '../ContactUsModal'

const CarouselItem = ({ key, data }) => {
  const [visibleModalKey, setVisibleModalKey] = useState('')
  const [contactModalKey, setContactModalKey] = useState('')
  const closeModal = () => {
    setVisibleModalKey('')
  }
  const closeContactModal = () => {
    setContactModalKey('')
  }
  useEffect(() => {
    console.log('contactModalKey', contactModalKey)
  }, [contactModalKey])
  return (
    <div className='flex flex-col md:flex-row w-full md:justify-between md:items-center py-2 lg:py-4'>
      {data.map((plan) => {
        return (
          <div className='w-full md:w-[32%] flex flex-col plan-shadow pb-1 pt-[30px] lg:pt-[60px] px-1 md:px-2 rounded-[20px] bg-[white] mt-[15px] lg:mt-[30px] md:mt-[0px]'>
            <div className='px-1 lg:px-2 md:px-5'>
              {plan?.attributes?.title && (
                <h1 className='text-center font-semibold text-[18px] lg:text-[24px] text-[#282828]'>
                  {plan.attributes.title}
                </h1>
              )}
              {plan?.attributes?.description && (
                <p className='text-center font-medium text-[13px] lg:text-[16px] text-[#344054] mt-[8px] lg:mt-[15px]'>
                  {plan.attributes.description}
                </p>
              )}
            </div>
            {plan?.attributes?.price && (
              <div className='my-[20px] lg:my-[40px]'>
                <h1 className='text-center font-normal text-[30px] lg:text-[42px]'>
                  {plan.attributes.price}
                </h1>
              </div>
            )}
            <div className='mx-[5px] lg:my-[10px] pt-[15px] lg:pt-[30px] bg-[#f2f4f7] rounded-xl'>
              <div className='flex flex-col pl-[30px] lg:pl-[60px]'>
                {plan?.attributes?.topFeatures?.map((feature) => (
                  <>
                    {feature?.title && (
                      <div className='flex mt-2 lg:mt-5'>
                        <span className='mr-1'>
                          <CheckIcon />
                        </span>
                        <span className='font-medium text-[14px] lg:text-[16px]'>
                          {feature?.title}
                        </span>
                      </div>
                    )}
                  </>
                ))}
                <button
                  onClick={() => {
                    setVisibleModalKey(plan?.attributes?.title)
                  }}
                  className='mt-[7px] lg:mt-[14px] w-[60px] flex justify-start items-center font-semibold text-[#0c4a6e] hover:text-[#2563eb]'
                >
                  See All
                </button>
                <button
                  onClick={() => {
                    setContactModalKey(plan.attributes?.title)
                  }}
                  className='my-[30px] lg:my-[70px] bg-black flex justify-center w-[80%] py-2 lg:py-4 font-semibold text-white text-[14px] lg:text-[16px] rounded-[15px]'
                >
                  {plan?.attributes?.btnText}
                </button>
                {visibleModalKey == plan?.attributes?.title && (
                  <PlanModal closeModal={closeModal} plan={plan} />
                )}
                {contactModalKey == plan?.attributes?.title && (
                  <ContactUsModal closeModal={closeContactModal} plan={plan} />
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
const PlanCarasoul = ({ heading, text }) => {
  const [yearlyActive, setYearlyActive] = useState(false)
  const [monthlyPlans, setMonthlyPlans] = useState([])
  const [yearlyPlans, setYearlyPlans] = useState([])

  const handleNext = () => {
    setYearlyActive(true)
  }

  const handlePrevious = () => {
    setYearlyActive(false)
  }

  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    let monthly = [],
      yearly = []
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/plans?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.length > 0) {
        res.data.data.forEach((plan) => {
          if (plan?.attributes?.monthly) {
            monthly.push(plan)
          } else {
            yearly.push(plan)
          }
        })
        if (monthly?.length) {
          setMonthlyPlans(monthly)
        }
        if (yearly?.length) {
          setYearlyPlans(yearly)
        }
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    getPageData()
  }, [])
  return (
    <>
      <div className='flex justify-center items-center w-full py-8 mx-auto lg:my-10'>
        <div className='w-full'>
          <div className='flex flex-col justify-cetner'>
            {heading && (
              <h1 className='text-xl md:text-3xl lg:text-4xl font-bold text-center'>{heading}</h1>
            )}
            {text && (
              <div className=' my-[20px] lg:my-[50px] text-center'>
                <span className='font-normal text-base lg:text-2xl text-[#282828]'>{text}</span>
              </div>
            )}
            {/* <div className='flex justify-center lg:mt-0 lg:justify-center lg:ml-16 space-x-4'>
              <div className='bg-white rounded p-1 lg:p-2 flex cont-shadow'>
                <button
                  onClick={handlePrevious}
                  disabled={!yearlyActive}
                  className={`${!yearlyActive ? 'bg-[#CEE5FF]' : 'bg-white'} text-[#191A15] font-medium text-[16px] lg:text-[18px] w-[110px] lg:w-[160px] h-[40px] lg:h-[48px] flex justify-center items-center border-0 rounded`}
                >
                  Monthly
                </button>
                <button
                  onClick={handleNext}
                  disabled={yearlyActive}
                  className={`${yearlyActive ? 'bg-[#CEE5FF]' : 'bg-white'} text-[#191A15] font-medium text-[16px] lg:text-[18px] w-[110px] lg:w-[160px] h-[40px] lg:h-[48px] flex justify-center items-center border-0 rounded`}
                >
                  Yearly
                </button>
              </div>
            </div> */}
          </div>
          <div className='w-full py-4 lg:py-8 sm:py-16 lg:py-32'>
            {!yearlyActive && monthlyPlans?.length > 0 && (
              <CarouselItem key={'monthly_plans'} data={monthlyPlans} />
            )}
            {yearlyActive && yearlyPlans?.length > 0 && (
              <CarouselItem key={'yearly_plans'} data={yearlyPlans} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default PlanCarasoul
