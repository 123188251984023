import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import CheckIcon from '../../assets/svg/CheckIcon'
import './styles.scss'

const PlanModal = ({ closeModal, plan }) => {
  const [visible, setVisible] = useState(false)
  const handleCancel = () => {
    setVisible(false)
    closeModal()
  }

  useEffect(() => {
    setVisible(true)
  }, [])
  return (
    <div className='planModal'>
      <Modal visible={visible} onCancel={handleCancel} footer={null}>
        <div
          className='w-full flex flex-col pb-1 pt-[60px] px-2 rounded-[20px] bg-[white]'
          key={plan?.attributes?.title}
        >
          <div className='px-1 lg:px-2 md:px-5'>
            <h1 className='text-center font-semibold text-[18px] lg:text-[24px] text-[#282828]'>
              {plan?.attributes?.title}
            </h1>
            <p className='text-center font-medium text-[13px] lg:text-[16px] text-[#344054] mt-[8px] lg:mt-[15px]'>
              {plan?.attributes?.description}
            </p>
          </div>
          <div className='my-[20px] lg:my-[40px]'>
            <h1 className='text-center font-normal text-[30px] lg:text-[42px]'>
              ${plan?.attributes?.price}
            </h1>
          </div>
          <div className='mx-[5px] lg:my-[10px] pt-[15px] lg:pt-[30px] bg-[#f2f4f7] rounded-xl'>
            <div className='flex flex-col pl-[30px] lg:pl-[60px]'>
              {plan?.attributes?.allFeatures?.map((feature) => (
                <div className='flex mt-2 lg:mt-5'>
                  <span className='mr-1'>
                    <CheckIcon />
                  </span>
                  <span className='font-medium text-[14px] lg:text-[16px]'>{feature?.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default PlanModal
