import { Finance } from 'financejs'
import usStates from './usStates'
import React, { useEffect, useState } from 'react'
import { useForm } from 'sunflower-antd'
import {
  Input,
  Button,
  Form,
  InputNumber,
  Select,
  DatePicker,
  Switch,
  Image,
  message,
  Upload,
  Spin,
  AutoComplete,
} from 'antd'
import InputField from '../InputField'
import moment from 'moment'
import './styles.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { calc } from 'antd/es/theme/internal'
import { showSuccessNotification } from '../../notifications/Success/notification'
import { showErrorNotification } from '../../notifications/Error/notification'
import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select
let finance = new Finance()
const PropertyForm = ({
  setValues,
  property,
  setProperty,
  scenario,
  setScenario,
  createPropertyAndScenario,
  disableRunScenario,
  setDisableRunScenario,
  imgUrl,
  setImgUrl,
  imgId,
  setImgId,
  imgLoading,
  setImgLoading,
  loading,
}) => {
  const [inputValue, setInputValue] = useState('')
  const handleBeforeUpload = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase()
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg']

    if (!allowedExtensions.includes(fileExtension)) {
      message.error('You can only upload JPG/PNG/SVG files!')
      return false
    }
    if (file && file.size) {
      if (file.size / 1024 / 1024 < 1) {
        // message.success('image uploaded success')
        handleUpload(file)
        return false
      } else {
        message.error('Logo must be smaller than 1MB!')
      }
    }
    return false
  }
  const handleUpload = async (file) => {
    setImgLoading(true)
    try {
      delete file.uid
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/file?fileType=${file.type}`,
      )
      const { url, key, file: imgFile } = data
      const reader = new FileReader()
      reader.onload = async (e) => {
        let bits = e.target.result
        let dataa = {
          originalFilename: file.name,
          filename: file.name,
          mimeType: file.type,
          fileSizeBytes: file.size,
          lastModified: file.lastModified,
          bin: bits,
        }

        let contentType = dataa.mimeType
        let binaryString = dataa.bin
        let bytes = new Uint8Array(binaryString.length)

        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }

        let imageFile = new Blob([bytes], { type: contentType })
        const config = {
          method: 'put',
          url: url,
          headers: {
            'Content-Type': file.type,
          },
          data: imageFile,
        }
        try {
          const response = await axios.request(config)
          if (response?.status == 200) {
            const imgResponse = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/file/${imgFile?.id}`,
            )
            if (imgResponse?.data?.file?.id && imgResponse?.data?.file?.url) {
              setImgUrl(imgResponse.data.file.url)
              setImgId(imgResponse.data.file.id)
              setProperty({ ...property, img_id: imgResponse.data.file.id })
            }
          }
          setImgLoading(false)
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsBinaryString(file)
    } catch (error) {
      console.log('error in image upload')
      setImgLoading(false)
    }
  }

  const navigate = useNavigate()
  const [disabledPayoffDate, setDisablePayoffDate] = useState(true)
  const [form] = Form.useForm()
  const { formProps, formValues, formResult, formLoading, submit } = useForm({
    form,
    async submit(formValues) {
      console.log('submit', formValues)
      await new Promise((r) => setTimeout(r, 1000))
      return 'ok'
    },
  })

  const [calculations, setCalculations] = useState({})
  const [validationTrigger, setValidationTrigger] = useState({
    property: {
      address: ['onBlur'],
      city: ['onBlur'],
      state: ['onBlur'],
      zipcode: ['onBlur'],
      type: ['onBlur'],
      bedrooms: ['onBlur'],
      bathrooms: ['onBlur'],
      area: ['onBlur'],
      lotSize: ['onBlur'],
      yearBuilt: ['onBlur'],
    },
    scenario: {
      hold_startDate: ['onBlur'],
      hold_payOffDate: ['onBlur'],
      purchase_purchasePrice: ['onBlur'],
      purchase_appraisalFee: ['onBlur'],
      purchase_inspectionFee: ['onBlur'],
      purchase_closingCostsNumeric: ['onBlur'],
      purchase_closingCostsPercentage: ['onBlur'],
      isPccDollar: ['onBlur'],
      financing_loanAmount: ['onBlur'],
      financing_loanOriginationNumeric: ['onBlur'],
      financing_loanOriginationPercentage: ['onBlur'],
      isLocDollar: ['onBlur'],
      financing_interestRateNumeric: ['onBlur'],
      financing_interestRatePercentage: ['onBlur'],
      isIrrDollar: ['onBlur'],
      rehab_material: ['onBlur'],
      rehab_contractor: ['onBlur'],
      holding_propertyTaxes: ['onBlur'],
      holding_insurance: ['onBlur'],
      holding_utilities: ['onBlur'],
      holding_other: ['onBlur'],
      selling_afterRepairValue: ['onBlur'],
      selling_commissionToAgentsNumeric: ['onBlur'],
      selling_commissionToAgentsPercentage: ['onBlur'],
      isCtaDollar: ['onBlur'],
      selling_closingCostNumeric: ['onBlur'],
      selling_closingCostPercentage: ['onBlur'],
      isSccDollar: ['onBlur'],
    },
  })
  const [isPccDollar, setIsPccDollar] = useState(true)
  const [isLocDollar, setIsLocDollar] = useState(false)
  const [isIrrDollar, setIsIrrDollar] = useState(false)
  const [isCtaDollar, setIsCtaDollar] = useState(true)
  const [isSccDollar, setIsSccDollar] = useState(false)
  const [showBrIcon, setShowBrIcon] = useState(-1)
  const [showBtIcon, setShowBtIcon] = useState(-1)
  const [showSqIcon, setShowSqIcon] = useState(false)
  const [showLsIcon, setShowLsIcon] = useState(false)
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  }

  const validateZipCode = (rule, value) => {
    const zipCodeRegex = /^\d{5}$/

    if (!zipCodeRegex.test(value)) {
      return Promise.reject('Please enter a 5-digit zip code in number format (e.g., 23300).')
    }

    return Promise.resolve()
  }

  const onFinish = (values) => {
    console.log(values)
  }
  const handleFieldsChange = (_, allFields) => {
    makeCalculations(allFields)
    if (_?.length) {
      const currentObj = _[0]
      if (currentObj?.validated && currentObj?.errors?.length && currentObj?.name?.length) {
        const main = currentObj.name[0]
        const sec = currentObj.name[1]
        const validator = validationTrigger
        if (!validator[`${main}`][`${sec}`].includes('onChange')) {
          validator[`${main}`][`${sec}`].push('onChange')
          setValidationTrigger({
            property: validator.property,
            scenario: validator.scenario,
          })
        }
      } else if (currentObj?.name[1] == 'hold_startDate') {
        setDisablePayoffDate(false)
      }
    }
  }
  const numberFormator = (value) => {
    // Assuming value is a string, remove any non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, '')
    // Format with commas for thousands
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const validatePayoffDate = (_, value) => {
    // Validate payoff date to be greater than the start date
    const { startDate } = form.getFieldsValue(['hold_startDate'])

    if (startDate && value && value <= startDate) {
      return Promise.reject('Payoff date must be greater than the start date')
    }

    return Promise.resolve()
  }
  const payOffDateDisabler = (current) => {
    try {
      const { scenario } = form.getFieldsValue(['scenario'])
      let customDate = moment(scenario?.hold_startDate?.['$d']).format('YYYY-MM-DD')

      return current < moment(customDate, 'YYYY-MM-DD')
    } catch (error) {
      return true
    }
  }
  const handleBrChange = (value) => {
    if (value > 0) {
      setShowBrIcon(value)
    } else {
      setShowBrIcon(-1)
    }
  }
  const handleBtChange = (value) => {
    if (value > 0) {
      setShowBtIcon(value)
    } else {
      setShowBtIcon(-1)
    }
  }
  const handleSqChange = (value) => {
    if (value) {
      setShowSqIcon(true)
    } else {
      setShowSqIcon(false)
    }
  }
  const handleLsChange = (value) => {
    if (value) {
      setShowLsIcon(true)
    } else {
      setShowLsIcon(false)
    }
  }
  const handleRunAnotherScenario = async () => {
    const data = form.getFieldsValue()
    if (data) {
      if (calculations?.purchase_costs !== null && calculations?.purchase_costs >= 0) {
        data.scenario['purchase_costs'] = parseInt(calculations.purchase_costs)
      }
      if (calculations.rehab_costs !== null && calculations.rehab_costs >= 0) {
        data.scenario['rehab_costs'] = parseInt(calculations.rehab_costs)
      }
      if (calculations.holding_costs !== null && calculations.holding_costs?.toString()) {
        data.scenario['holding_costs'] = parseInt(calculations.holding_costs)
      }
      if (calculations?.financing_loanAmount !== null && calculations.financing_loanAmount >= 0) {
        data.scenario['financing_loanAmount'] = parseInt(calculations.financing_loanAmount)
      }
      if (calculations?.loan_origin_amount !== null && calculations.loan_origin_amount >= 0) {
        data.scenario['loan_origin_amount'] = parseInt(calculations.loan_origin_amount)
      }
      if (calculations?.financing_costs !== null && calculations.financing_costs >= 0) {
        data.scenario['financing_costs'] = parseInt(calculations.financing_costs)
      }
      if (calculations?.selling_costs !== null && calculations?.selling_costs >= 0) {
        data.scenario['selling_costs'] = parseInt(calculations.selling_costs)
      }
      if (
        calculations?.out_of_pocket_cost !== null &&
        calculations?.out_of_pocket_cost?.toString()
      ) {
        data.scenario['out_of_pocket_cost'] = parseInt(calculations.out_of_pocket_cost)
      }
      if (calculations?.profit !== null && calculations?.profit?.toString()) {
        data.scenario['profit'] = parseInt(calculations.profit)
      }
      if (calculations?.roi?.toString() && calculations?.roi !== null) {
        data.scenario['roi'] = parseFloat(calculations.roi)
      }
      if (calculations?.annualised_roi !== null && calculations?.annualised_roi?.toString()) {
        data.scenario['annualised_roi'] = parseFloat(calculations.annualised_roi)
      }
      if (calculations?.xirr !== null && calculations?.xirr?.toString()) {
        data.scenario['xirr'] = parseFloat(calculations.xirr)
      }
    }
    const res = await createPropertyAndScenario(data)
    if (res?.propertyRes?.id) {
      showSuccessNotification('Success', 'Property is created successfully')
      navigate(`/property/${res?.propertyRes?.id}`)
    } else {
      showErrorNotification('Error while creating Property', 'Required Information is missing')
    }
  }
  const makeCalculations = (allFields) => {
    console.log('allFields', allFields)
    let tempCalculations = { ...calculations }
    let purchase_costs = null,
      financing_costs = null,
      rehab_costs = null,
      holding_costs = null,
      selling_costs = null,
      sellingCostsCommssion = null,
      sellingCostsClosing = null
    allFields.map((item, index) => {
      if (
        item.name[1] == 'isPccDollar' ||
        item.name[1] == 'isSccDollar' ||
        item.name[1] == 'isCtaDollar' ||
        item.name[1] == 'isLocDollar' ||
        item.name[1] == 'isIrrDollar'
      ) {
        tempCalculations[`${item.name[1]}`] = item.value
      } else {
        if (!item?.error?.length && /*item.touched &&*/ item.value?.toString()) {
          if (tempCalculations[`${item.name[1]}`]) {
            tempCalculations[`${item.name[1]}`] = item.value
          } else {
            tempCalculations[`${item.name[1]}`] = item.value
          }
        } else {
          if (tempCalculations[`${item.name[1]}`]) {
            delete tempCalculations[`${item.name[1]}`]
          } else {
            delete tempCalculations[`${item.name[1]}`]
          }
        }
      }
    })
    if (tempCalculations['isPccDollar'] !== isPccDollar) {
      setIsPccDollar(!isPccDollar)
    }
    if (tempCalculations['isLocDollar'] !== isLocDollar) {
      setIsLocDollar(!isLocDollar)
    }
    if (tempCalculations['isIrrDollar'] !== isIrrDollar) {
      setIsIrrDollar(!isIrrDollar)
    }
    if (tempCalculations['isSccDollar'] !== isSccDollar) {
      setIsSccDollar(!isSccDollar)
    }
    if (tempCalculations['isCtaDollar'] !== isCtaDollar) {
      setIsCtaDollar(!isCtaDollar)
    }

    //calculating Purchase Costs Start
    if (
      tempCalculations['purchase_appraisalFee']?.toString() &&
      tempCalculations['purchase_inspectionFee']?.toString() &&
      tempCalculations['isPccDollar']?.toString() &&
      ((tempCalculations['purchase_closingCostsPercentage']?.toString() &&
        tempCalculations['purchase_purchasePrice']?.toString()) ||
        tempCalculations['purchase_closingCostsNumeric']?.toString())
    ) {
      // purchase_costs = tempCalculations['purchase_purchasePrice'] +
      purchase_costs =
        tempCalculations['purchase_appraisalFee'] + tempCalculations['purchase_inspectionFee']
      if (tempCalculations['isPccDollar']) {
        purchase_costs = purchase_costs + tempCalculations['purchase_closingCostsNumeric']
      } else {
        purchase_costs =
          purchase_costs +
          (tempCalculations['purchase_purchasePrice'] / 100) *
            tempCalculations['purchase_closingCostsPercentage']
      }
      if (!isNaN(purchase_costs)) {
        tempCalculations['purchase_costs'] = purchase_costs
      } else {
        tempCalculations['purchase_costs'] = null
      }
    } else {
      tempCalculations['purchase_costs'] = null
    }
    //calculating Purchase Costs End
    //calculating Loan Origination Amount Start
    if (
      tempCalculations?.financing_loanAmount?.toString() &&
      tempCalculations?.isLocDollar?.toString() &&
      (tempCalculations?.financing_loanOriginationNumeric?.toString() ||
        tempCalculations?.financing_loanOriginationPercentage?.toString())
    ) {
      let loan_origin_amount = null
      try {
        if (isLocDollar) {
          loan_origin_amount = tempCalculations.financing_loanOriginationNumeric
        } else {
          loan_origin_amount =
            (tempCalculations.financing_loanAmount / 100) *
            tempCalculations.financing_loanOriginationPercentage
        }
      } catch (error) {
        loan_origin_amount = null
      }
      if (!isNaN(loan_origin_amount)) {
        tempCalculations['loan_origin_amount'] = loan_origin_amount
      } else {
        tempCalculations['loan_origin_amount'] = null
      }
    } else {
      tempCalculations['loan_origin_amount'] = null
    }
    //calculating Loan Origination Amount End
    //calculating Financing Costs Start
    if (
      tempCalculations['financing_loanAmount']?.toString() &&
      tempCalculations['isLocDollar']?.toString() &&
      (tempCalculations['financing_loanOriginationNumeric']?.toString() ||
        tempCalculations['financing_loanOriginationPercentage']?.toString()) &&
      tempCalculations['isIrrDollar']?.toString() &&
      (tempCalculations['financing_interestRateNumeric']?.toString() ||
        tempCalculations['financing_interestRatePercentage']?.toString())
    ) {
      if (isLocDollar) {
        financing_costs =
          tempCalculations['financing_loanAmount'] -
          tempCalculations['financing_loanOriginationNumeric']
      } else {
        let tempCost =
          (tempCalculations['financing_loanAmount'] / 100) *
          tempCalculations['financing_loanOriginationPercentage']
        financing_costs = tempCalculations['financing_loanAmount'] - tempCost
      }
      if (tempCalculations['isIrrDollar']) {
        financing_costs = financing_costs + tempCalculations['financing_interestRateNumeric']
      } else {
        financing_costs =
          financing_costs +
          (tempCalculations['financing_loanAmount'] / 100) *
            tempCalculations['financing_interestRatePercentage']
      }
      if (!isNaN(financing_costs)) {
        tempCalculations['financing_costs'] = financing_costs
      } else {
        tempCalculations['financing_costs'] = null
      }
    } else {
      tempCalculations['financing_costs'] = null
    }
    //calculating Financing Costs End

    //calculating Rehab Costs Start
    if (
      tempCalculations['rehab_material']?.toString() &&
      tempCalculations['rehab_contractor']?.toString()
    ) {
      rehab_costs = tempCalculations['rehab_material'] + tempCalculations['rehab_contractor']
      if (!isNaN(rehab_costs)) {
        tempCalculations['rehab_costs'] = rehab_costs
      } else {
        tempCalculations['rehab_costs'] = null
      }
    } else {
      tempCalculations['rehab_costs'] = null
    }
    //calculating Rehab Costs End

    //calculating Interest Payments Costs Start
    try {
      if (
        tempCalculations['financing_loanAmount']?.toString() &&
        moment(tempCalculations?.hold_startDate)?.isValid() &&
        moment(tempCalculations?.hold_payOffDate)?.isValid() &&
        tempCalculations['isIrrDollar']?.toString() &&
        (tempCalculations['financing_interestRateNumeric']?.toString() ||
          tempCalculations['financing_interestRatePercentage']?.toString())
      ) {
        let holdTime =
          Math.ceil(
            (tempCalculations?.hold_payOffDate - tempCalculations?.hold_startDate) / 86400000,
          ) + 1
        let tempInterestPayment = null
        if (isIrrDollar) {
          tempCalculations['interestPayments'] = tempCalculations['financing_interestRateNumeric']
        } else {
          tempInterestPayment =
            (tempCalculations['financing_loanAmount'] / 100) *
            tempCalculations['financing_interestRatePercentage']
          if (!isNaN(tempInterestPayment)) {
            tempCalculations['interestPayments'] = tempInterestPayment * (holdTime / 365)
          } else {
            tempCalculations['interestPayments'] = null
          }
        }
      } else {
        tempCalculations['interestPayments'] = null
      }
    } catch (error) {
      tempCalculations['interestPayments'] = null
    }
    //calculating Interest Payments Costs End

    //calculating Holding Costs Start
    if (
      tempCalculations['holding_propertyTaxes']?.toString() &&
      tempCalculations['holding_insurance']?.toString() &&
      tempCalculations['holding_utilities']?.toString() &&
      tempCalculations['holding_other']?.toString() &&
      tempCalculations['interestPayments']?.toString()
    ) {
      holding_costs =
        tempCalculations['holding_propertyTaxes'] +
        tempCalculations['holding_insurance'] +
        tempCalculations['holding_utilities'] +
        tempCalculations['holding_other'] +
        tempCalculations['interestPayments']
      if (!isNaN(holding_costs)) {
        tempCalculations['holding_costs'] = holding_costs
      } else {
        tempCalculations['holding_costs'] = null
      }
    } else {
      tempCalculations['holding_costs'] = null
    }
    //calculating Holding Costs End

    if (
      tempCalculations['isCtaDollar']?.toString() &&
      (tempCalculations['selling_commissionToAgentsNumeric']?.toString() ||
        tempCalculations['selling_commissionToAgentsPercentage']?.toString()) &&
      tempCalculations['selling_afterRepairValue']?.toString()
    ) {
      if (
        tempCalculations['isCtaDollar'] &&
        tempCalculations['selling_commissionToAgentsNumeric']
      ) {
        sellingCostsCommssion = tempCalculations['selling_commissionToAgentsNumeric']
      } else if (
        !tempCalculations['isCtaDollar'] &&
        tempCalculations['selling_afterRepairValue'] &&
        tempCalculations['selling_commissionToAgentsPercentage']
      ) {
        sellingCostsCommssion =
          (tempCalculations['selling_afterRepairValue'] / 100) *
          tempCalculations['selling_commissionToAgentsPercentage']
      }
    }
    if (
      tempCalculations['isSccDollar']?.toString() &&
      (tempCalculations['selling_closingCostNumeric']?.toString() ||
        tempCalculations['selling_closingCostPercentage']?.toString()) &&
      tempCalculations['selling_afterRepairValue']?.toString()
    ) {
      if (tempCalculations['isSccDollar'] && tempCalculations['selling_closingCostNumeric']) {
        sellingCostsClosing = tempCalculations['selling_closingCostNumeric']
      } else if (
        !tempCalculations['isSccDollar'] &&
        tempCalculations['selling_afterRepairValue'] &&
        tempCalculations['selling_closingCostPercentage']
      ) {
        sellingCostsClosing =
          (tempCalculations['selling_afterRepairValue'] / 100) *
          tempCalculations['selling_closingCostPercentage']
      }
    }
    if (!isNaN(sellingCostsCommssion) && !isNaN(sellingCostsClosing)) {
      selling_costs = sellingCostsClosing + sellingCostsCommssion
      if (!isNaN(selling_costs)) {
        tempCalculations['selling_costs'] = selling_costs
      }
    } else {
      tempCalculations['selling_costs'] = null
    }

    //calculating Selling Costs End

    //calculating Total Expenses Start
    if (
      tempCalculations?.purchase_costs?.toString() &&
      tempCalculations?.rehab_costs?.toString() &&
      tempCalculations?.holding_costs?.toString() &&
      tempCalculations?.selling_costs?.toString() &&
      tempCalculations?.loan_origin_amount?.toString()
    ) {
      let tempTotalExp =
        tempCalculations.purchase_costs +
        tempCalculations.rehab_costs +
        tempCalculations.holding_costs +
        tempCalculations.selling_costs +
        tempCalculations.loan_origin_amount
      if (!isNaN(tempTotalExp)) {
        tempCalculations['totalExpenses'] = tempTotalExp
      } else {
        tempCalculations['totalExpenses'] = null
      }
    } else {
      tempCalculations['totalExpenses'] = null
    }
    //calculating Total Expenses End
    //calculating Profit Start
    if (
      tempCalculations?.totalExpenses?.toString() &&
      tempCalculations?.selling_afterRepairValue?.toString() &&
      tempCalculations?.purchase_purchasePrice?.toString()
    ) {
      let tempProfit =
        -tempCalculations.totalExpenses +
        tempCalculations.selling_afterRepairValue -
        tempCalculations.purchase_purchasePrice
      if (!isNaN(tempProfit)) {
        tempCalculations['profit'] = tempProfit
      } else {
        tempCalculations['profit'] = null
      }
    } else {
      tempCalculations['profit'] = null
    }
    //calculating Profit End
    //calculating out Of pocket costs Start
    if (
      tempCalculations?.loan_origin_amount?.toString() &&
      tempCalculations?.holding_costs?.toString() &&
      tempCalculations?.rehab_costs?.toString() &&
      tempCalculations?.purchase_costs?.toString() &&
      tempCalculations?.purchase_purchasePrice?.toString()
    ) {
      let out_of_pocket_cost =
        tempCalculations.holding_costs +
        tempCalculations.rehab_costs +
        tempCalculations.purchase_costs +
        tempCalculations.purchase_purchasePrice -
        tempCalculations.financing_loanAmount +
        tempCalculations.loan_origin_amount
      if (!isNaN(out_of_pocket_cost)) {
        tempCalculations['out_of_pocket_cost'] = out_of_pocket_cost
      } else {
        tempCalculations['out_of_pocket_cost'] = null
      }
    } else {
      tempCalculations['out_of_pocket_cost'] = null
    }
    //calculating out Of pocket costs End
    //calculating ROI Start
    if (tempCalculations?.profit?.toString() && tempCalculations?.out_of_pocket_cost?.toString()) {
      let roi = (tempCalculations.profit / tempCalculations.out_of_pocket_cost) * 100
      if (!isNaN(roi)) {
        tempCalculations['roi'] = roi
      } else {
        tempCalculations['roi'] = null
      }
    } else {
      tempCalculations['roi'] = null
    }

    if (
      moment(tempCalculations?.hold_startDate)?.isValid() &&
      moment(tempCalculations?.hold_payOffDate)?.isValid() &&
      tempCalculations?.roi?.toString()
    ) {
      let holdTime = Math.ceil(
        (tempCalculations?.hold_payOffDate - tempCalculations?.hold_startDate) / 86400000,
      )
      let annualRoi = tempCalculations.roi / ((holdTime + 1) / 365)
      if (!isNaN(annualRoi)) {
        tempCalculations['annualised_roi'] = annualRoi
      } else {
        tempCalculations['annualised_roi'] = null
      }
    } else {
      tempCalculations['annualised_roi'] = null
    }
    //calculating ROI End
    //calculating XIRR Start
    try {
      if (
        moment(tempCalculations?.hold_startDate)?.isValid() &&
        moment(tempCalculations?.hold_payOffDate)?.isValid() &&
        tempCalculations?.purchase_costs?.toString() &&
        tempCalculations?.rehab_costs?.toString() &&
        tempCalculations?.holding_costs?.toString() &&
        tempCalculations?.selling_costs?.toString() &&
        tempCalculations?.financing_loanAmount?.toString() &&
        tempCalculations?.purchase_costs?.toString() &&
        tempCalculations?.selling_afterRepairValue?.toString() &&
        tempCalculations?.profit?.toString() &&
        tempCalculations?.purchase_purchasePrice?.toString() &&
        tempCalculations?.loan_origin_amount?.toString()
      ) {
        console.log('start -- running this method')
        const startDate = tempCalculations.hold_startDate // June is represented as 5 because months are zero-based
        const endDate = tempCalculations.hold_payOffDate

        const datesArray = []
        let cashFlowArray = []
        const currentDate = new Date(startDate)

        while (currentDate <= endDate) {
          datesArray.push(new Date(currentDate)) // Push a new Date object to avoid modifying the original dates
          currentDate.setDate(currentDate.getDate() + 1) // Increment date by one day
        }
        let holdDays = datesArray.length
        let rehabCostsPerDay = tempCalculations.rehab_costs / holdDays
        let holdingCostsPerDay = tempCalculations.holding_costs / holdDays
        cashFlowArray = datesArray.map((item, index) => {
          let flow = -rehabCostsPerDay - holdingCostsPerDay
          if (index == 0) {
            flow =
              flow -
              (tempCalculations.purchase_costs +
                tempCalculations.purchase_purchasePrice +
                -tempCalculations.financing_loanAmount +
                tempCalculations.loan_origin_amount)
          } else if (index == holdDays - 1) {
            flow =
              flow +
              tempCalculations.selling_afterRepairValue -
              tempCalculations.selling_costs +
              -tempCalculations.financing_loanAmount +
              tempCalculations.loan_origin_amount
          }
          return flow
        })
        const xirr = finance.XIRR(cashFlowArray, datesArray, 0.1)
        if (!isNaN(xirr)) {
          tempCalculations['xirr'] = xirr
        } else {
          tempCalculations['xirr'] = null
        }
        console.log('end 1-- running this method')
      } else {
        tempCalculations['xirr'] = null
        console.log('end 2-- running this method')
      }
    } catch (error) {
      tempCalculations['xirr'] = null
      console.log('error in XIRR')
      console.log('end 3-- running this method')
    }
    //calculating XIRR End
    if (
      tempCalculations?.purchase_purchasePrice !== null &&
      tempCalculations?.purchase_costs !== null &&
      tempCalculations?.rehab_costs !== null &&
      tempCalculations?.holding_costs !== null &&
      tempCalculations?.financing_loanAmount !== null &&
      tempCalculations?.financing_costs !== null &&
      tempCalculations?.selling_costs !== null &&
      tempCalculations?.out_of_pocket_cost !== null &&
      tempCalculations?.roi !== null &&
      tempCalculations?.annualised_roi !== null &&
      tempCalculations?.xirr !== null &&
      tempCalculations?.purchase_purchasePrice?.toString() &&
      tempCalculations?.purchase_costs?.toString() &&
      tempCalculations?.rehab_costs?.toString() &&
      tempCalculations?.holding_costs?.toString() &&
      tempCalculations?.financing_loanAmount?.toString() &&
      tempCalculations?.financing_costs?.toString() &&
      tempCalculations?.selling_costs?.toString() &&
      tempCalculations?.out_of_pocket_cost?.toString() &&
      tempCalculations?.roi?.toString() &&
      tempCalculations?.annualised_roi?.toString() &&
      tempCalculations?.xirr?.toString()
    ) {
      setDisableRunScenario(false)
    } else {
      setDisableRunScenario(true)
    }
    setCalculations({ ...tempCalculations })
    setValues(tempCalculations)
  }
  useEffect(() => {
    console.log('calulations', calculations)
  }, [calculations])
  useEffect(() => {
    if (form) {
      let formValues = {}
      formValues['isSccDollar'] = isSccDollar ?? false
      formValues['isCtaDollar'] = isCtaDollar ?? false
      formValues['isPccDollar'] = isPccDollar ?? false
      formValues['isLocDollar'] = isLocDollar ?? false
      formValues['isIrrDollar'] = isIrrDollar ?? false
      form.setFieldsValue({ scenario: formValues })
    }
  }, [form])
  useEffect(() => {
    console.log('isLocDollar', isLocDollar)
  }, [isLocDollar])
  return (
    <div className='w-full lg:w-[83%] lg:p-3 sm:p-0 mx-auto property-form sm:mt-0 mt-[584px] home-form'>
      <div className='w-full md:w-3/4 lg:w-1/2 border border-[#2F2B431A] bg-[#FCFCFD] lg:rounded-[24px] sm:rounded-none sm:mx-2 md:px-[32px] py-[24px] mx-auto'>
        <button
          onClick={async () => {
            await submit()
          }}
        ></button>
        <Form
          layout='inline'
          {...formProps}
          onFinish={onFinish}
          onFieldsChange={handleFieldsChange}
          className='flex flex-col mx-2 md:mx-8'
          style={{ maxWidth: '100%', md: 600 }}
          disabled={loading}
          initialValues={{
            scenario: {
              purchase_purchasePrice: 0,
              purchase_appraisalFee: 0,
              purchase_inspectionFee: 0,
              purchase_closingCostsNumeric: 0,
              purchase_closingCostsPercentage: 0,
              financing_loanAmount: 0,
              financing_loanOriginationNumeric: 0,
              financing_loanOriginationPercentage: 0,
              financing_interestRateNumeric: 0,
              financing_interestRatePercentage: 0,
              rehab_material: 0,
              rehab_contractor: 0,
              holding_propertyTaxes: 0,
              holding_insurance: 0,
              holding_utilities: 0,
              holding_other: 0,
              selling_afterRepairValue: 0,
              selling_commissionToAgentsNumeric: 0,
              selling_commissionToAgentsPercentage: 0,
              selling_closingCostNumeric: 0,
              selling_closingCostPercentage: 0,
            },
          }}
        >
          {/* Start <--> Property Address Inputs */}
          <div className='flex flex-col w-full text-gray-700 lg:ml-0 md:ml-0 ml-2'>
            <h1 className='my-2 text-sm font-medium leading-5'>Property Address</h1>
            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-y-4 w-full '>
              {/* Start <--> Address Field */}
              <InputField
                objName={'property'}
                fieldName={'address'}
                rules={[
                  {
                    required: true,
                    message: 'Address is required!',
                  },
                  {
                    min: 7,
                    message: 'Address should be minimum 7 letters!',
                  },
                ]}
                validationTrigger={validationTrigger}
                placeholder={'Enter Address'}
              />
              {/* End <--> Address Field */}
              {/* Start <--> City Field */}
              <InputField
                objName={'property'}
                fieldName={'city'}
                rules={[
                  {
                    required: true,
                    message: 'City is required!',
                  },
                  {
                    min: 2,
                    message: 'City should be minimum 2 letters!',
                  },
                ]}
                validationTrigger={validationTrigger}
                placeholder={'City'}
              />
              {/* End <--> City Field */}
              {/* Start <--> State Field */}
              <Form.Item
                name={['property', 'state']}
                rules={[
                  {
                    required: true,
                    message: 'State is required!',
                  },
                ]}
                className='col-span-1'
              >
                <AutoComplete
                  placeholder='Select State'
                  className='w-full'
                  options={usStates
                    .filter((stateName) =>
                      stateName.toLowerCase().startsWith(inputValue.toLowerCase()),
                    )
                    .map((stateName) => ({
                      value: stateName,
                      label: stateName,
                    }))}
                  onSearch={(value) => setInputValue(value)}
                />
              </Form.Item>
              {/*add "handleFieldsChange" in this when user select value check value is add or not /}
              {/* End <--> State Field */}
              {/* Start <--> Zip Code Field */}
              <Form.Item
                name={['property', 'zipcode']}
                rules={[
                  {
                    required: true,
                    message: 'Zip Code is required!',
                  },
                  {
                    validator: validateZipCode,
                  },
                ]}
                validateTrigger={validationTrigger.property.zipcode}
                className='col-span-1'
              >
                <Input
                  controls='false'
                  placeholder='Zipcode'
                  className='w-full numberInput'
                />
              </Form.Item>
              {/* End <--> Zip Code Field */}
            </div>
          </div>
          {/* End <--> Property Address Inputs */}

          {/* Start <--> Property Info Inputs */}
          <div className='flex flex-col text-gray-700'>
            <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col sm:w-[200px] sm:h-[100px] h-[170px] ml-2 sm:m-0'>
                <h1 className='mt-6 mb-2 text-sm leading-5 font-medium'>Property photo</h1>
                {imgLoading ? (
                  <div className='flex items-center justify-center h-[100px]'>
                    {' '}
                    <Spin />
                  </div>
                ) : (
                  <>
                    {imgId && imgUrl ? (
                      <div className='relative'>
                        <span className='pointer absolute right-0 top-0 z-50 w-6 h-6 bg-white border flex items-center justify-center'>
                          <svg
                            className='h-5 w-5 fill-[red] hover:fill-[black] cursor-pointer'
                            viewBox='0 0 24 24'
                            onClick={() => {
                              setImgLoading(true)
                              setTimeout(() => {
                                setImgId(null)
                                setImgUrl(null)
                                const tempProperty = property
                                if (tempProperty?.img_id) {
                                  delete tempProperty.img_id
                                  setProperty({ ...tempProperty })
                                }
                                setImgLoading(false)
                              }, 2000)
                            }}
                          >
                            <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z'></path>
                          </svg>
                        </span>
                        <Image.PreviewGroup>
                          <Image width={200} src={imgUrl} height={100} />
                        </Image.PreviewGroup>
                      </div>
                    ) : (
                      <div className='p-4 border-dashed border-2 border-gray-300 text-center'>
                        <p>Select a file or drag and drop here</p>
                        <Upload
                          beforeUpload={handleBeforeUpload}
                          listType='picture'
                          // onChange={handleUpload}
                          showUploadList={false}
                          accept='.jpg,.png,.svg'
                        >
                          <Button
                            className='bg-blue-500 hover:bg-blue-700 text-white flex items-center justify-center font-bold py-2 px-4 rounded mt-3'
                            icon={<UploadOutlined />}
                          >
                            Select File
                          </Button>
                        </Upload>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='flex flex-col lg:ml-0 md:ml-0 ml-2'>
                <h1 className='mt-6 text-sm font-medium'>
                  Property Info <span className='text-xs font-normal'>(all optional)</span>{' '}
                </h1>
                <div className='w-full'>
                  {/* Start <--> Property Type Field */}
                  <div className=''>
                    <Form.Item
                      name={['property', 'type']}
                      validateTrigger={validationTrigger.property.type}
                      className='my-2'
                    >
                      <Select name='type' placeholder='Property Type'>
                        <Option value='Single-Family' key='Single-Family'>
                          Single-Family
                        </Option>
                        <Option value='Residence' key='Residence'>
                          Residence
                        </Option>
                        <Option value='Duplex' key='Duplex'>
                          Duplex
                        </Option>
                        <Option value='Triplex' key='Triplex'>
                          Triplex
                        </Option>
                        <Option value='Apartment Complex' key='Apartment Complex'>
                          Apartment Complex
                        </Option>
                        <Option value='Other' key='Other'>
                          Other
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  {/* End <--> Property Type Field */}

                  {/* Start <--> Bedrooms Field */}
                  <div className=''>
                    <Form.Item
                      name={['property', 'bedrooms']}
                      validateTrigger={validationTrigger.property.bedrooms}
                      className='my-2'
                    >
                      <Select name='bedrooms' placeholder='Bedrooms' onChange={handleBrChange}>
                        <Option value={1} key='1-bed'>
                          {showBrIcon == 1 ? <span>1 BR</span> : 1}
                        </Option>
                        <Option value={2} key='2-bed'>
                          {showBrIcon == 2 ? <span>2 BR</span> : 2}
                        </Option>
                        <Option value={3} key='3-bed'>
                          {showBrIcon == 3 ? <span>3 BR</span> : 3}
                        </Option>
                        <Option value={4} key='4-bed'>
                          {showBrIcon == 4 ? <span>4 BR</span> : 4}
                        </Option>
                        <Option value={5} key='5-bed'>
                          {showBrIcon == 5 ? <span>5 BR</span> : 5}
                        </Option>
                        <Option value={6} key='6-bed'>
                          {showBrIcon == 6 ? <span>6 BR</span> : 6}
                        </Option>
                        <Option value={7} key='7-bed'>
                          {showBrIcon == 7 ? <span>7 BR</span> : 7}
                        </Option>
                        <Option value={8} key='8-bed'>
                          {showBrIcon == 8 ? <span>8 BR</span> : 8}
                        </Option>
                        <Option value={9} key='9-bed'>
                          {showBrIcon == 9 ? <span>9 BR</span> : 9}
                        </Option>
                        <Option value={10} key='10-bed'>
                          {showBrIcon == 10 ? <span>10 BR</span> : 10}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  {/* End <--> Bedrooms Field */}

                  {/* Start <--> Bathrooms Field */}
                  <div className=' '>
                    <Form.Item
                      name={['property', 'bathrooms']}
                      validateTrigger={validationTrigger.property.bathrooms}
                      className='my-2'
                    >
                      <Select name='bathrooms' placeholder='Bathrooms' onChange={handleBtChange}>
                        <Option value={1} key='1-bath'>
                          {showBtIcon == 1 ? <span>1 BT</span> : 1}
                        </Option>
                        <Option value={2} key='2-bath'>
                          {showBtIcon == 2 ? <span>2 BT</span> : 2}
                        </Option>
                        <Option value={3} key='3-bath'>
                          {showBtIcon == 3 ? <span>3 BT</span> : 3}
                        </Option>
                        <Option value={4} key='4-bath'>
                          {showBtIcon == 4 ? <span>4 BT</span> : 4}
                        </Option>
                        <Option value={5} key='5-bath'>
                          {showBtIcon == 5 ? <span>5 BT</span> : 5}
                        </Option>
                        <Option value={6} key='6-bath'>
                          {showBtIcon == 6 ? <span>6 BT</span> : 6}
                        </Option>
                        <Option value={7} key='7-bath'>
                          {showBtIcon == 7 ? <span>7 BT</span> : 7}
                        </Option>
                        <Option value={8} key='8-bath'>
                          {showBtIcon == 8 ? <span>8 BT</span> : 8}
                        </Option>
                        <Option value={9} key='9-bath'>
                          {showBtIcon == 9 ? <span>9 BT</span> : 9}
                        </Option>
                        <Option value={10} key='10-bath'>
                          {showBtIcon == 10 ? <span>10 BT</span> : 10}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  {/* End <--> Bathrooms Field */}

                  {/* Start <--> Square Feet Field */}
                  <div className='numberInput'>
                    <Form.Item
                      name={['property', 'area']}
                      rules={[
                        {
                          type: Number,
                          message: 'Only Enter Numbers',
                        },
                      ]}
                      validateTrigger={validationTrigger.property.area}
                      className='my-2'
                    >
                      <InputNumber
                        className='w-full numberInput'
                        controls='false'
                        placeholder='Sq Ft'
                        suffix={showSqIcon ? 'Sq ft' : null}
                        onChange={handleSqChange}
                      />
                    </Form.Item>
                  </div>
                  {/* End <--> Square Feet Field */}

                  {/* Start <--> Lot Size Field */}
                  <div className='numberInput'>
                    <Form.Item
                      name={['property', 'lotSize']}
                      rules={[
                        {
                          type: Number,
                          message: 'Only Enter Numbers',
                        },
                      ]}
                      validateTrigger={validationTrigger.property.lotSize}
                      className='my-2'
                    >
                      <InputNumber
                        className='w-full numberInput'
                        controls='false'
                        placeholder='Lot Size'
                        suffix={showLsIcon ? 'Sq ft' : null}
                        onChange={handleLsChange}
                      />
                    </Form.Item>
                  </div>
                  {/* end <--> Lot Size Field */}
                  {/* Start <--> Select year Field */}
                  <div className=''>
                    <Form.Item
                      name={['property', 'yearBuilt']}
                      validateTrigger={validationTrigger.property.yearBuilt}
                      className='my-2'
                    >
                      <DatePicker className='w-full' picker='year' />
                    </Form.Item>
                  </div>
                  {/* Start <--> Select year Field */}
                </div>
              </div>
            </div>
          </div>
          {/* End <--> Property Info Inputs */}
          <div className='flex flex-col'>
            {/*Start selecting date */}
            <div className='flex flex-col  text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 font-semibold text-base'>Hold Time</h1>
              <div className=''>
                <h3 className='mt-4 font-medium text-sm'>
                  Start date of loan / payoff date of loan
                </h3>
                <div className='flex flex-row'>
                  <Form.Item
                    name={['scenario', 'hold_startDate']}
                    validateTrigger={validationTrigger.scenario.hold_startDate}
                    className='my-2'
                  >
                    <DatePicker placeholder='Select Date' format='YYYY-MM-DD' />
                  </Form.Item>
                  <Form.Item
                    className='my-2'
                    name={['scenario', 'hold_payOffDate']}
                    rules={[
                      {
                        required: true,
                        message: 'Please select an end date',
                      },
                      {
                        validator: validatePayoffDate,
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder='Select Date'
                      disabled={disabledPayoffDate}
                      disabledDate={payOffDateDisabler}
                      format='YYYY-MM-DD'
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            {/*end selecting date */}

            {/* start Purchase Price */}
            <div className='flex flex-col text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 text-base font-semibold'>Purchase Costs</h1>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 mt-2 my-2'>
                <div>
                  <h2 className='mb-1 font-medium text-sm'>Purchase Price</h2>
                  <div>
                    <Form.Item
                      name={['scenario', 'purchase_purchasePrice']}
                      rules={[
                        {
                          required: true,
                          message: 'Purchase costs is required!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.purchase_purchasePrice}
                      className='my-2'
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* Appraisal Fees */}
                <div>
                  <h2 className='mb-1 font-medium text-sm'>Appraisal Fees</h2>
                  <div>
                    <Form.Item
                      name={['scenario', 'purchase_appraisalFee']}
                      rules={[
                        {
                          required: true,
                          message: 'Appraisal Fees is required!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.purchase_appraisalFee}
                      className='my-2'
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* Inspaction / Surveys fees */}
                <div>
                  <h2 className='font-medium text-sm'>Inspection / Surveys fees</h2>
                  <div>
                    <Form.Item
                      name={['scenario', 'purchase_inspectionFee']}
                      rules={[
                        {
                          required: true,
                          message: 'Inspection / Surveys Fees is required!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.purchase_inspectionFee}
                      className='my-3.5'
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* Closing costs */}
                <div>
                  <div className='grid grid-cols-2 items-center'>
                    <div>
                      <h2 className='mb-3 font-medium text-sm'>Closing costs</h2>
                    </div>

                    <div className='flex items-center justify-end'>
                      <span
                        className={`mr-3 mb-3 ${isPccDollar ? 'text-[#98A2B3]' : 'text-[#344054]'}`}
                      >
                        %
                      </span>
                      <Form.Item name={['scenario', 'isPccDollar']}>
                        <Switch
                          className='mb-1'
                          style={
                            isPccDollar
                              ? { backgroundColor: '#7F56D9' }
                              : { backgroundColor: '#12B76A' }
                          }
                          validateTrigger={validationTrigger.scenario.isPccDollar}
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 ${isPccDollar ? 'text-[#344054]' : 'text-[#98A2B3]'}`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className={`${isPccDollar ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={['scenario', 'purchase_closingCostsNumeric']}
                      rules={[
                        {
                          required: true,
                          message: 'Closing costs is required!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          min: 0,
                          message: 'Closing costs must be greater than or equal to 0!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.purchase_closingCostsNumeric}
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                        precision={0}
                      />
                    </Form.Item>
                  </div>
                  <div className={`${isPccDollar ? 'hidden' : 'block'}`}>
                    <Form.Item
                      name={['scenario', 'purchase_closingCostsPercentage']}
                      rules={[
                        {
                          required: true,
                          message: 'Closing costs is required!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          max: 99,
                          message: 'Closing costs must be less than 100!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          min: 0,
                          message: 'Closing costs must be greater than or equal to 0!',
                        },
                        {
                          pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          message: 'Closing costs can have up to two decimal places!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.purchase_closingCostsPercentage}
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix='%'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00.00'
                        precision={2}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            {/* end <--> purchase price  Inputs */}

            {/* start <--> Financing Inputs */}
            <div className='flex flex-col  text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 text-base font-semibold'>Financing</h1>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 my-2'>
                {/* First Row */}
                <div className='numberInput'>
                  {/* Start <--> loan amount Field */}
                  <h2 className='mb-1 font-medium text-sm'>Loan amount</h2>
                  <Form.Item
                    name={['scenario', 'financing_loanAmount']}
                    rules={[{ required: true, message: 'Loan amount is required!' }]}
                    validateTrigger={validationTrigger.scenario.financing_loanAmount}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls={false}
                      placeholder='00'
                      formatter={numberFormator}
                      validationTrigger={['OnChange']}
                    />
                  </Form.Item>
                  {/* End <--> Loan amount Field */}
                </div>

                {/* // */}
                <div className='numberInput'>
                  {/* Start <--> Loan origination cost Field */}
                  <div className='grid grid-cols-2 items-center'>
                    <div>
                      <h2 className='mb-3 font-medium text-sm'>Loan origination</h2>
                    </div>
                    <div className='flex justify-end items-center'>
                      <span
                        className={`mr-3 mb-3 ${isLocDollar ? 'text-[#98A2B3]' : 'text-[#344054]'}`}
                      >
                        %
                      </span>
                      <Form.Item name={['scenario', 'isLocDollar']}>
                        <Switch
                          className='mb-1'
                          style={
                            isLocDollar
                              ? { backgroundColor: '#7F56D9' }
                              : { backgroundColor: '#12B76A' }
                          }
                          validateTrigger={validationTrigger.scenario.isLocDollar}
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 ${isLocDollar ? 'text-[#344054]' : 'text-[#98A2B3]'}`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className={`${isLocDollar ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={['scenario', 'financing_loanOriginationNumeric']}
                      rules={[
                        {
                          required: true,
                          message: 'Loan origination cost is required!',
                        },
                        {
                          type: 'number',
                          message: 'Loan origination cost must be a number',
                          min: 0,
                          message: 'Loan origination cost must be greater than or equal to 0!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.financing_loanOriginationNumeric}
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                        precision={0}
                      />
                    </Form.Item>
                  </div>
                  <div className={`${isLocDollar ? 'hidden' : 'block'}`}>
                    <Form.Item
                      name={['scenario', 'financing_loanOriginationPercentage']}
                      rules={[
                        {
                          required: true,
                          message: 'Loan origination cost is required!',
                        },
                        {
                          type: 'number',
                          message: 'Loan origination cost must be a number',
                          max: 99,
                          message: 'Loan origination cost must be less than 100!',
                        },
                        {
                          type: 'number',
                          message: 'Loan origination cost must be a number',
                          min: 0,
                          message: 'Loan origination cost must be greater than or equal to 0!',
                        },
                        {
                          pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          message: 'Loan origination cost can have up to two decimal places!',
                        },
                      ]}
                      validateTrigger={
                        validationTrigger.scenario.financing_loanOriginationPercentage
                      }
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix='%'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00.00'
                        precision={2}
                      />
                    </Form.Item>
                  </div>

                  {/* End <--> Loan origination cost Field */}
                </div>

                <div className='numberInput'>
                  {/* Start <--> Interest rate Field */}
                  <div className='grid grid-cols-2 items-center'>
                    <div>
                      <h2 className='mb-3 font-medium text-sm'>Interest rate</h2>
                    </div>
                    <div className='flex justify-end items-center'>
                      <span
                        className={`mr-3 mb-3 ${isIrrDollar ? 'text-[#98A2B3]' : 'text-[#344054]'}`}
                      >
                        %
                      </span>
                      <Form.Item name={['scenario', 'isIrrDollar']}>
                        <Switch
                          className='mb-1'
                          style={
                            isIrrDollar
                              ? { backgroundColor: '#7F56D9' }
                              : { backgroundColor: '#12B76A' }
                          }
                          validateTrigger={validationTrigger.scenario.isIrrDollar}
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 ${isIrrDollar ? 'text-[#344054]' : 'text-[#98A2B3]'}`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className={`${isIrrDollar ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={['scenario', 'financing_interestRateNumeric']}
                      rules={[
                        {
                          required: true,
                          message: 'Interest rate is required!',
                        },
                        {
                          type: 'number',
                          message: 'Interest rate must be a number',
                          min: 0,
                          message: 'Interest rate must be greater than or equal to 0!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.financing_interestRateNumeric}
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                        precision={0}
                      />
                    </Form.Item>
                  </div>
                  <div className={`${isIrrDollar ? 'hidden' : 'block'}`}>
                    <Form.Item
                      name={['scenario', 'financing_interestRatePercentage']}
                      rules={[
                        {
                          required: true,
                          message: 'Interest rate  is required!',
                        },
                        {
                          type: 'number',
                          message: 'Interest rate must be a number',
                          max: 99,
                          message: 'Interest rate must be less than 100!',
                        },
                        {
                          type: 'number',
                          message: 'Interest rate must be a number',
                          min: 0,
                          message: 'Interest rate must be greater than or equal to 0!',
                        },
                        {
                          pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          message: 'Interest rate can have up to two decimal places!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.financing_interestRatePercentage}
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix='%'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00.00'
                        precision={2}
                      />
                    </Form.Item>
                  </div>

                  {/* End <--> Interest rate Field */}
                </div>
              </div>
            </div>
            {/* End <--> Financing Inputs */}

            {/* start <--> Rehab Costs Inputs */}
            <div className='flex flex-col text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 text-base font-semibold'>Rehab costs</h1>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 my-2'>
                <div className='numberInput'>
                  {/* Start <--> materials Field */}
                  <h2 className='mb-1 font-medium text-sm'>Materials</h2>
                  <Form.Item
                    name={['scenario', 'rehab_material']}
                    rules={[
                      {
                        required: true,
                        message: 'material is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.rehab_material}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> Materials  Field */}

                {/* Start <--> Contractor/labour  Field */}

                <div className='numberInput'>
                  <h2 className=' font-medium text-sm'>Contractor / Labour</h2>
                  <Form.Item
                    name={['scenario', 'rehab_contractor']}
                    rules={[
                      {
                        required: true,
                        message: 'Contractor/labor is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.rehab_contractor}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>

                {/* Start <--> contractor/labour Field */}
              </div>
            </div>
            {/* End <--> Rehab Inputs */}

            {/* start <--> Holding Costs Inputs */}
            <div className='flex flex-col text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 text-base font-semibold'>Holding costs</h1>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 my-2 numberInput'>
                <div className=''>
                  {/* Start <--> property texes Input Field */}
                  <h2 className='mb-1 font-medium text-sm '>Property taxes</h2>
                  <Form.Item
                    name={['scenario', 'holding_propertyTaxes']}
                    rules={[
                      {
                        required: true,
                        message: 'Property taxes required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.holding_propertyTaxes}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> Property texes Input  Field */}

                {/* Start <--> Insurance input Field */}
                <div className=''>
                  <h2 className='mb-1 font-medium text-sm'>Insurance</h2>
                  <Form.Item
                    name={['scenario', 'holding_insurance']}
                    rules={[
                      {
                        required: true,
                        message: 'instructor is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.holding_insurance}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* end <--> Insurance input Field */}

                {/* Start <--> Utilities input Field */}
                <div className=''>
                  <h2 className='mb-1 font-medium text-sm'>Utilities</h2>
                  <Form.Item
                    name={['scenario', 'holding_utilities']}
                    rules={[
                      {
                        required: true,
                        message: 'utilities is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.holding_utilities}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* end <--> Utilities input Field */}

                {/* Start <--> others input Field */}
                <div className=''>
                  <h2 className='mb-1 font-medium text-sm'>Other</h2>
                  <Form.Item
                    name={['scenario', 'holding_other']}
                    rules={[
                      {
                        required: true,
                        message: 'is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.holding_other}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>

                {/* Start <--> others input Field */}
              </div>
            </div>
            {/* End <--> Holding costs Inputs */}

            {/* start <--> selling Costs Inputs */}
            <div className='flex flex-col text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              <h1 className='mt-6 text-base font-semibold'>Selling Cost</h1>
              <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 my-2 numberInput'>
                {/* Start <--> After repair value (ARV) Input Field */}
                <div>
                  <h2 className='font-medium text-sm'>After repair value (ARV)</h2>
                  <Form.Item
                    name={['scenario', 'selling_afterRepairValue']}
                    rules={[
                      {
                        required: true,
                        message: 'Property taxes required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.scenario.selling_afterRepairValue}
                    className='my-3.5'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full numberInput'
                      controls='false'
                      placeholder='00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> After repair value (ARV)  Field */}

                {/* Start <--> Commission input Field */}
                <div>
                  <div className='grid grid-cols-2'>
                    <h2 className='lg:mb-3 font-medium text-sm w-36'>Commission</h2>

                    <div className='flex justify-end items-center '>
                      <span
                        className={`mr-2 mb-3 ${isCtaDollar ? 'text-[#98A2B3]' : 'text-[#344054]'}`}
                      >
                        %
                      </span>
                      <Form.Item name={['scenario', 'isCtaDollar']}>
                        <Switch
                          className='mb-1'
                          style={
                            isCtaDollar
                              ? { backgroundColor: '#7F56D9' }
                              : { backgroundColor: '#12B76A' }
                          }
                          validateTrigger={validationTrigger.scenario.isCtaDollar}
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 ${isCtaDollar ? 'text-[#344054]' : 'text-[#98A2B3]'}`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className={`${isCtaDollar ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={['scenario', 'selling_commissionToAgentsNumeric']}
                      rules={[
                        {
                          required: true,
                          message: 'Commision to agents is required!',
                        },
                        {
                          type: 'number',
                          message: 'Commision to agents must be a number',
                          min: 0,
                          message: 'Commision to agents must be greater than or equal to 0!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.selling_commissionToAgentsNumeric}
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                        precision={0}
                      />
                    </Form.Item>
                  </div>
                  <div className={`${isCtaDollar ? 'hidden' : 'block'}`}>
                    <Form.Item
                      name={['scenario', 'selling_commissionToAgentsPercentage']}
                      rules={[
                        {
                          required: true,
                          message: 'Commision to agents  is required!',
                        },
                        {
                          type: 'number',
                          message: 'Commision to agents must be a number',
                          max: 99,
                          message: 'Commision to agents must be less than 100!',
                        },
                        {
                          type: 'number',
                          message: 'Commision to agents must be a number',
                          min: 0,
                          message: 'Commision to agents must be greater than or equal to 0!',
                        },
                        {
                          pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          message: 'Commision to agents can have up to two decimal places!',
                        },
                      ]}
                      validateTrigger={
                        validationTrigger.scenario.selling_commissionToAgentsPercentage
                      }
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix='%'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00.00'
                        precision={2}
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* end <--> Commission to agents input Field */}

                {/* Start <--> Closing costs input Field */}
                <div>
                  <div className='grid grid-cols-2 items-center'>
                    <h2 className='mb-3 font-medium text-sm'>Closing costs</h2>

                    <div className=' flex justify-end items-center'>
                      <span
                        className={`mr-3 mb-3 ${isSccDollar ? 'text-[#98A2B3]' : 'text-[#344054]'}`}
                      >
                        %
                      </span>
                      <Form.Item name={['scenario', 'isSccDollar']}>
                        <Switch
                          className='mb-1'
                          style={
                            isSccDollar
                              ? { backgroundColor: '#7F56D9' }
                              : { backgroundColor: '#12B76A' }
                          }
                          validateTrigger={validationTrigger.scenario.isSccDollar}
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 justify-end ${isSccDollar ? 'text-[#344054]' : 'text-[#98A2B3]'}`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className={`${isSccDollar ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={['scenario', 'selling_closingCostNumeric']}
                      rules={[
                        {
                          required: true,
                          message: 'Closing costs is required!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          min: 0,
                          message: 'Closing costs must be greater than or equal to 0!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.selling_closingCostNumeric}
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix='$'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00'
                        formatter={numberFormator}
                        precision={0}
                      />
                    </Form.Item>
                  </div>
                  <div className={`${isSccDollar ? 'hidden' : 'block'}`}>
                    <Form.Item
                      name={['scenario', 'selling_closingCostPercentage']}
                      rules={[
                        {
                          required: true,
                          message: 'Closing costs  is required!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          max: 99,
                          message: 'Closing costs must be less than 100!',
                        },
                        {
                          type: 'number',
                          message: 'Closing costs must be a number',
                          min: 0,
                          message: 'Closing costs must be greater than or equal to 0!',
                        },
                        {
                          pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          message: 'Closing costs can have up to two decimal places!',
                        },
                      ]}
                      validateTrigger={validationTrigger.scenario.selling_closingCostPercentage}
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix='%'
                        className='w-full numberInput'
                        controls={false}
                        placeholder='00.00'
                        precision={2}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* end <--> Closing costs  input Field */}
              </div>
            </div>
            {/* End <--> selling costs Inputs */}

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 8,
              }}
            ></Form.Item>
            <div className='flex flex-col md:flex-row lg:flex-row w-full justify-center p-4 gap-4'>
              <Button
                disabled={true}
                className='md:w-48 lg:w-48 w-full h-10 font-bold justify-center items-center'
              >
                Delete this Scenario
              </Button>
              <Button
                className='md:w-48 lg:w-48 w-full h-10 font-bold text-white bg-[#7F56D9] justify-center items-center'
                onClick={handleRunAnotherScenario}
                disabled={disableRunScenario}
              >
                Run Another Scenario
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}
export default PropertyForm
