import React from 'react'

function Tiktok() {
  return (
    <div>
      <svg
        width='23'
        height='25'
        viewBox='0 0 23 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.76386 9.95008V9.01578C8.43154 8.97302 8.09369 8.9463 7.74475 8.9463C3.58526 8.94103 0.201172 12.2033 0.201172 16.2184C0.201172 18.6798 1.47505 20.8582 3.42464 22.177C2.16738 20.8796 1.39751 19.1336 1.39751 17.2222C1.39751 13.2658 4.68744 10.0409 8.76386 9.95008Z'
          fill='black'
        />
        <path
          d='M8.94574 20.5436C10.8012 20.5436 12.3187 19.118 12.3853 17.3454L12.3908 1.50378H15.3927C15.3317 1.17809 15.2985 0.841715 15.2985 0.5H11.2L11.1944 16.3416C11.128 18.1142 9.61035 19.5398 7.75495 19.5398C7.17894 19.5398 6.63616 19.401 6.1543 19.1554C6.77462 19.9936 7.79373 20.5436 8.94574 20.5436Z'
          fill='black'
        />
        <path
          d='M21.0036 6.88006V5.99913C19.8572 5.99913 18.7937 5.67341 17.8965 5.10742C18.694 5.98844 19.774 6.62917 21.0036 6.88006Z'
          fill='black'
        />
        <path
          d='M17.8963 5.10788C17.0212 4.1415 16.495 2.88143 16.495 1.50391H15.3984C15.6808 3.00958 16.6114 4.30169 17.8963 5.10788Z'
          fill='#282828'
        />
        <path
          d='M7.7497 12.8926C5.84995 12.8926 4.30469 14.3823 4.30469 16.2136C4.30469 17.4898 5.05793 18.6003 6.15457 19.1556C5.74472 18.611 5.50103 17.9436 5.50103 17.2174C5.50103 15.3861 7.04629 13.8964 8.94607 13.8964C9.30053 13.8964 9.64389 13.9551 9.96514 14.0512V10.0148C9.63279 9.97204 9.29498 9.94531 8.94607 9.94531C8.88511 9.94531 8.82973 9.95066 8.7688 9.95066V13.0474C8.44202 12.9513 8.10417 12.8926 7.7497 12.8926Z'
          fill='#282828'
        />
        <path
          d='M21.0054 6.87988V9.94994C18.8785 9.94994 16.9068 9.29325 15.3005 8.18265V16.2183C15.3005 20.228 11.9165 23.4957 7.75143 23.4957C6.14523 23.4957 4.64981 23.0045 3.42578 22.1769C4.80489 23.6024 6.77109 24.4995 8.94781 24.4995C13.1073 24.4995 16.4969 21.2372 16.4969 17.222V9.18644C18.1031 10.297 20.0748 10.9537 22.2017 10.9537V7.00273C21.7862 7.00273 21.3875 6.95997 21.0054 6.87988Z'
          fill='#282828'
        />
        <path
          d='M15.3003 16.2189V8.18328C16.9066 9.29388 18.8783 9.95057 21.0051 9.95057V6.88051C19.7755 6.62963 18.6955 5.98889 17.898 5.10788C16.613 4.30169 15.6881 3.00958 15.3945 1.50391H12.3926L12.387 17.3454C12.3206 19.1181 10.803 20.5437 8.94759 20.5437C7.79553 20.5437 6.78196 19.9937 6.15056 19.1608C5.05391 18.6002 4.30067 17.495 4.30067 16.2189C4.30067 14.3875 5.84593 12.8979 7.74568 12.8979C8.10015 12.8979 8.44354 12.9566 8.76478 13.0527V9.95592C4.68837 10.0414 1.39844 13.2663 1.39844 17.2227C1.39844 19.1341 2.1683 20.8748 3.42556 22.1775C4.6496 23.0051 6.14502 23.4963 7.75122 23.4963C11.9107 23.4963 15.3003 20.2287 15.3003 16.2189Z'
          fill='#C0C0C0'
        />
      </svg>
    </div>
  )
}

export default Tiktok
