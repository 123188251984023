import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import Layout from './components/Layout'
import Property from './pages/Property'
import Thankyoupage from './pages/Thankyoupage'
import LeadGen from './pages/LeadGen'
import New from './pages/New'
import ContactUs from './pages/ContactUs'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AboutUs from './pages/AboutUs'
import Blogs from './pages/Blogs'
import Category from './pages/Category'
import Blog from './pages/Blog'
import PerformingNotes from './pages/PerformingNotes'
import CreatedPerformingNotes from './pages/CreatedPerformingNotes'
import BookACall from './pages/BookACall'
import PageNotFound from './pages/PageNotFound'
import RoutesHOC from './routes/RoutesHOC'

function App() {
  return (
    <BrowserRouter>
      <RoutesHOC />
    </BrowserRouter>
  )
}

export default App
