import React, { useEffect } from 'react'
import Performing from '../../assets/images/performing.png'
import './styles.scss'
const ScrollingCardPerforming = ({
  property,
  pageType,
  values,
  scenarios,
  activeScenarioId,
  makeAmortizationCalculations,
  loading,
  numberFormator,
  fixDecimals,
  setOpenNoteSharingModal,
  openNoteSharingModal,
}) => {
  useEffect(() => {
    console.log('openNoteSharingModal', openNoteSharingModal)
  }, [openNoteSharingModal])
  return (
    <div className='block md:absolute top-[331px] left-0 right-0 md:top-0 md:bottom-[11px] md:left-[58%] z-[400] pt-[130px] mt-[200px] md:mt-0'>
      <div className='w-full lg:w-[584px] sticky top-[40px] left-[40px]'>
        <div className='w-full'>
          <div className='performingValues relative bg-white p-4 lg:rounded-xl sm:rounded-[0px] lg:shadow-xl sm:shadow-none'>
            <div className='flex justify-between items-center flex-col'>
              <span className='absolute top-[-100%] right-0'>
                <img src={Performing} />
              </span>
              <div className='text-start w-full font-semibold text-[18px] text-[#1D2939]'>
                Performing Note Value
              </div>
              <div className='min-h-[28px] my-2 mb-[10px] text-start w-full font-semibold text-[14px] text-[#1D2939]'>
                <span className='mr-1'>{values?.address ?? ''}</span> {values?.city ?? ''}
              </div>
              <div className='flex justify-between items-center flex-wrap w-full'>
                <div className='w-[45%]'>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='text-[14px] font-semibold'>Months Left:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.monthsLeft ?? '0'}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='text-[14px] font-semibold'>Loan Amount Left:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.upb ? <>$ {numberFormator(fixDecimals(values.upb))}</> : '$ 0.00'}
                    </span>
                  </div>
                  <div className='w-full flex justify-between'>
                    <span className='text-[14px] font-semibold'>Due Diligence Cost:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.dueDiligenceCost ? (
                        <>$ {numberFormator(fixDecimals(values.dueDiligenceCost))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                </div>
                <div className='w-[45%]'>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='text-[14px] font-semibold'>Interest Rate:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.ir ? <>{numberFormator(fixDecimals(values.ir))} %</> : '0.00 %'}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='text-[14px] font-semibold'>Monthly Payment:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.pmt ? <>$ {numberFormator(fixDecimals(values.pmt))} </> : '$ 0.00'}
                    </span>
                  </div>
                  <div className='w-full flex justify-between'>
                    <span className='text-[14px] font-semibold'>Serving Cost:</span>
                    <span className='text-[14px] font-normal text-[#475467]'>
                      {values?.monthlyServicingCost ? (
                        <>$ {numberFormator(fixDecimals(values.monthlyServicingCost))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className='mt-6 font-semibold text-[16px] text-center'>Performing Note Outcomes</h1>
          {scenarios?.length > 0 &&
            scenarios.map((item, index, self) => {
              if (activeScenarioId === 0 && index === self.length - 1) {
                item = values
              }
              const disable_1 = !item?.yield_1 || !item?.price_1 || !item?.upb_1 || loading
              const disable_2 = !item?.yield_2 || !item?.price_2 || !item?.upb_2 || loading
              const disable_3 = !item?.yield_3 || !item?.price_3 || !item?.upb_3 || loading
              return (
                <div
                  className={`${(activeScenarioId === 0 && index === self.length - 1) || (activeScenarioId > 0 && activeScenarioId == item.id) ? 'activeScenario' : 'nonActiveScenario cursor--pointer'} bg-[white] text-black py-3 lg:rounded-xl sm:rounded-[0px] lg:shadow-xl sm:shadow-none mt-[30px]`}
                >
                  <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-[33%] flex flex-col pb-[20px] md:pb-0 px-[20px] mr-0 md:mr-[20px] outcomes-border'>
                      <h1 className='font-semibold text-[14px] mb-[15px]'>% of UPB</h1>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>UPB:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.upb_1 ? (
                            <>{numberFormator(fixDecimals(item.upb_1))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>Yield:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.yield_1 ? (
                            <>{numberFormator(fixDecimals(item.yield_1))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-2'>
                        <span className='font-normal text-[14px]'>Price:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.price_1 ? (
                            <>$ {numberFormator(fixDecimals(item.price_1))}</>
                          ) : (
                            '$ 0.00'
                          )}
                        </span>
                      </div>
                      <button
                        className={`${disable_1 ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                        disabled={!item?.yield_1 || !item?.price_1 || !item?.upb_1 || loading}
                        onClick={() => {
                          let payload = values?.id && property?.monthsLeft ? values : property
                          makeAmortizationCalculations({ ...payload, ...item }, 'upb')
                        }}
                      >
                        View Amort
                      </button>
                    </div>
                    <div className='w-full md:w-[34%] flex flex-col py-[20px] md:py-0 px-[20px] md:pr-[20px] mr-0 md:mr-[20px] outcomes-border'>
                      <h1 className='font-semibold text-[14px] mb-[15px]'>% of Yield</h1>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>Yield:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.yield_2 ? (
                            <>{numberFormator(fixDecimals(item.yield_2))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>UPB:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.upb_2 ? (
                            <>{numberFormator(fixDecimals(item.upb_2))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-2'>
                        <span className='font-normal text-[14px]'>Price:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.price_2 ? (
                            <>$ {numberFormator(fixDecimals(item.price_2))}</>
                          ) : (
                            '$ 0.00'
                          )}
                        </span>
                      </div>
                      <button
                        className={`${disable_2 ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                        disabled={!item?.yield_2 || !item?.price_2 || !item?.upb_2 || loading}
                        onClick={() => {
                          let payload = values?.id && property?.monthsLeft ? values : property
                          makeAmortizationCalculations({ ...payload, ...item }, 'yield')
                        }}
                      >
                        View Amort
                      </button>
                    </div>
                    <div className='w-full md:w-[33%] flex flex-col pt-[20px] md:pt-0 px-[20px] md:pr-[20px]'>
                      <h1 className='font-semibold text-[14px] mb-[15px]'>Price</h1>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>Price:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.price_3 ? (
                            <>$ {numberFormator(fixDecimals(item.price_3))}</>
                          ) : (
                            '$ 0.00'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-1'>
                        <span className='font-normal text-[14px]'>UPB:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.upb_3 ? (
                            <>{numberFormator(fixDecimals(item.upb_3))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <div className='w-full flex justify-between mb-2'>
                        <span className='font-normal text-[14px]'>Yield:</span>
                        <span className='font-nomarl text-[14px] text-[#475467]'>
                          {item?.yield_3 ? (
                            <>{numberFormator(fixDecimals(item.yield_3))} %</>
                          ) : (
                            '0.00 %'
                          )}
                        </span>
                      </div>
                      <button
                        className={`${disable_3 ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                        disabled={!item?.yield_3 || !item?.price_3 || !item?.upb_3 || loading}
                        onClick={() => {
                          let payload = values?.id && property?.monthsLeft ? values : property
                          makeAmortizationCalculations({ ...payload, ...item }, 'price')
                        }}
                      >
                        View Amort
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          {/* {pageType == 'property' && (
            <div className='activeScenario bg-[white] text-black py-3 lg:rounded-xl sm:rounded-[0px] lg:shadow-xl sm:shadow-none mt-[30px]'>
              <div className='flex flex-col md:flex-row w-full justify-between'>
                <div className='w-full md:w-[33%] flex flex-col pb-[20px] md:pb-0 px-[20px] mr-0 md:mr-[20px] outcomes-border'>
                  <h1 className='font-semibold text-[14px] mb-[15px]'>% of UPB</h1>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>UPB:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.upb_1 ? (
                        <>$ {numberFormator(fixDecimals(values.upb_1))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>Yield:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.yield_1 ? (
                        <>{numberFormator(fixDecimals(values.yield_1))} %</>
                      ) : (
                        '0.00 %'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='font-normal text-[14px]'>Price:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.price_1 ? (
                        <>$ {numberFormator(fixDecimals(values.price_1))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                  <button
                    className={`${!values?.yield_1 || !values?.price_1 || !values?.upb_1 || loading ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                    disabled={!values?.yield_1 || !values?.price_1 || !values?.upb_1 || loading}
                    onClick={() => {
                      makeAmortizationCalculations(values, 'upb')
                    }}
                  >
                    View Amort
                  </button>
                </div>
                <div className='w-full md:w-[34%] flex flex-col py-[20px] md:py-0 px-[20px] md:pr-[20px] mr-0 md:mr-[20px] outcomes-border'>
                  <h1 className='font-semibold text-[14px] mb-[15px]'>% of Yield</h1>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>Yield:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.yield_2 ? (
                        <>{numberFormator(fixDecimals(values.yield_2))} %</>
                      ) : (
                        '0.00 %'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>UPB:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.upb_2 ? (
                        <>{numberFormator(fixDecimals(values.upb_2))} %</>
                      ) : (
                        '0.00 %'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='font-normal text-[14px]'>Price:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.price_2 ? (
                        <>$ {numberFormator(fixDecimals(values.upb_2))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                  <button
                    className={`${!values?.yield_2 || !values?.price_2 || !values?.upb_2 || loading ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                    disabled={!values?.yield_2 || !values?.price_2 || !values?.upb_2 || loading}
                    onClick={() => {
                      makeAmortizationCalculations(values, 'yield')
                    }}
                  >
                    View Amort
                  </button>
                </div>
                <div className='w-full md:w-[33%] flex flex-col pt-[20px] md:pt-0 px-[20px] md:pr-[20px]'>
                  <h1 className='font-semibold text-[16px] mb-[15px]'>Price</h1>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>Price:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.price_3 ? (
                        <>$ {numberFormator(fixDecimals(values.price_3))}</>
                      ) : (
                        '$ 0.00'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-1'>
                    <span className='font-normal text-[14px]'>UPB:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.upb_3 ? (
                        <>{numberFormator(fixDecimals(values.upb_3))} %</>
                      ) : (
                        '0.00 %'
                      )}
                    </span>
                  </div>
                  <div className='w-full flex justify-between mb-2'>
                    <span className='font-normal text-[14px]'>Yield:</span>
                    <span className='font-normal text-[14px] text-[#475467]'>
                      {values?.yield_3 ? (
                        <>{numberFormator(fixDecimals(values.yield_3))} %</>
                      ) : (
                        '0.00 %'
                      )}
                    </span>
                  </div>
                  <button
                    className={`${!values?.yield_3 || !values?.price_3 || !values?.upb_3 || loading ? 'bg-[#acb0b5]' : 'bg-[#7F56D9]'} rounded-3xl py-1 px-1 text-white font-medium `}
                    disabled={!values?.yield_3 || !values?.price_3 || !values?.upb_3 || loading}
                    onClick={() => {
                      makeAmortizationCalculations(values, 'price')
                    }}
                  >
                    View Amort
                  </button>
                </div>
              </div>
            </div>
          )} */}
          <button
            onClick={() => {
              console.log('openNoteSharingModal', openNoteSharingModal)
              if (openNoteSharingModal?.toString() && !openNoteSharingModal) {
                setOpenNoteSharingModal(true)
              }
            }}
            disabled={!property?.id}
            className={`${property?.id ? 'bg-[#7F56D9]' : 'bg-[#acb0b5]'} py-2 text-center w-full mt-6 text-[white] font-semibold rounded`}
          >
            Share Note Values
          </button>
        </div>
      </div>
    </div>
  )
}
export default ScrollingCardPerforming
