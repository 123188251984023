import { notification } from 'antd'

export const showSuccessNotification = (title = 'Success', message = '') => {
  notification.success({
    message: title,
    description: message,
    onClick: () => {
      console.log('Notification Clicked!')
    },
  })
}
