import { useEffect, useState } from 'react'
import { Form, Input, Modal, Button, notification, Select } from 'antd'
import { useForm } from 'sunflower-antd'
import TextArea from 'antd/es/input/TextArea'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneNumberInput from '../PhoneNumberInput'
import CheckIcon from '../../assets/svg/CheckIcon'
import axios from 'axios'
import './styles.scss'
import { Link } from 'react-router-dom'
const { Option } = Select
const ContactUsModal = ({ closeModal, plan }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [validationTrigger, setValidationTrigger] = useState({
    name: ['onBlur'],
    company: ['onBlur'],
    email: ['onBlur'],
    phoneNumber: ['onBlur'],
    investmentType: ['onBlur'],
    message: ['onBlur'],
    // privacyCheck: ['onBlur'],
  })

  const sourceOptions = [
    {
      value: 1,
      text: 'Short-Term Rentals',
    },
    {
      value: 2,
      text: 'Mid-Term or Long-Term Rentals',
    },
    { value: 3, text: 'Short / Mid and Long-Term Rentals' },
    { value: 4, text: 'Mortgage  Notes' },
    { value: 5, text: 'Hard Money Lender' },
    { value: 6, text: " I'm An Agent" },
  ]

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const { formProps, formValues, formResult, formLoading, submit } = useForm({
    form,
    async submit(formValues) {
      await new Promise((r) => setTimeout(r, 1000))
      return 'ok'
    },
  })
  const handlePhoneNumberChange = (value) => {
    setValue(value)
  }
  const validatePhoneNumber = (number) => {
    const isValid = isValidPhoneNumber(`+${number}`)
    return isValid
  }
  const handleFieldsChange = (_, allFields) => {
    if (_?.length) {
      const currentObj = _[0]
      if (currentObj?.validated && currentObj?.errors?.length && currentObj?.name?.length) {
        const main = currentObj.name[0]
        const validator = validationTrigger
        if (!validator[`${main}`].includes('onChange')) {
          validator[`${main}`].push('onChange')
          setValidationTrigger({
            ...validator,
          })
        }
      }
    }
  }
  const handleValuesChange = (_, allFields) => {
    try {
      if (
        allFields?.name &&
        allFields?.email &&
        allFields?.phoneNumber &&
        validatePhoneNumber(allFields?.phoneNumber) &&
        allFields?.investmentType
      ) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    } catch (error) {
      setIsButtonDisabled(true)
    }
  }
  const authenticate = async () => {
    try {
      const data = {
        grant_type: 'password',
        username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
        password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
        client_id: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
        client_secret: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_SECRET,
      }
      const response = await axios({
        url: 'https://api.podio.com/oauth/token/v2',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          grant_type: 'password',
          username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
          password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
          client_id: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
          client_secret: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_SECRET,
        },
      })
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.log('Error', error.response.data)
      return
    }
  }
  const storeDataInPodio = async (accessToken, values) => {
    try {
      const appId = process.env.REACT_APP_CONTACT_INFO_APP_ID
      const url = `https://api.podio.com/item/app/${appId}/`
      const payload = {
        fields: {
          plan: plan?.attributes?.title,
          name: values?.name,
          company: values?.company,
          email: [{ type: 'work', value: values?.email }],
          phone: [{ type: 'work', value: values?.phoneNumber }],
          'describe-your-real-estate-investing': values?.investmentType ? 1 : 2,
          message: values?.message,
        },
      }

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `OAuth2 ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      console.error('Error storing data in Podio:', error)
      return
    }
  }
  const onFinish = async (values) => {
    setLoading(true)
    const authorization = await authenticate()
    if (!authorization) {
      setLoading(false)
      return
    }
    const data = await storeDataInPodio(authorization?.access_token, values)
    if (!data) {
      setLoading(false)
      return
    }
    setLoading(false)
    form.resetFields()
    notification.success({
      message: 'Success',
      description: 'Information sent successfully',
    })
    setFormSubmitted(true)
    handleCancel()
  }
  const handleCancel = () => {
    closeModal()
    setVisible(false)
  }

  useEffect(() => {
    setVisible(true)
  }, [])

  useEffect(() => {
    console.log('visible', visible)
  }, [visible])
  return (
    <div className='contact-us-modal'>
      <Modal open={visible} onCancel={handleCancel} footer={null}>
        <div className='flex-col w-full items-center justify-center mx-auto'>
          <Form
            layout='inline'
            {...formProps}
            onFinish={onFinish}
            onFieldsChange={handleFieldsChange}
            className='flex flex-col mx-2 w-full'
            style={{ maxWidth: '100%', md: 900 }}
            onValuesChange={handleValuesChange}
          >
            <div className='flex flex-col items-start justify-between'>
              <h1 className='font-semibold text-[16px] mb-[15px]'>Contact Information</h1>
              <h1 className='font-semibold text-[14px] text-[#344054] mb-[6px]'>
                Name <span className='text-[red]'>*</span>
              </h1>
              <Form.Item
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Name is required!',
                  },
                ]}
                validateTrigger={validationTrigger.name}
                className='w-full'
              >
                <Input placeholder='Enter Name' className='w-full' />
              </Form.Item>
            </div>
            <div className='flex flex-col items-start justify-between mt-[14px]'>
              <h1 className='font-semibold text-[14px] mb-[6px]'>Company (optional)</h1>
              <Form.Item
                name='company'
                validateTrigger={validationTrigger.company}
                className='w-full'
              >
                <Input placeholder='Enter Company' className='w-full' />
              </Form.Item>
            </div>
            <div className='flex flex-col items-start justify-between mt-[14px]'>
              <h1 className='font-semibold text-[14px] mb-[6px]'>
                Email <span className='text-[red]'>*</span>
              </h1>
              <Form.Item
                name='email'
                validateTrigger={validationTrigger.email}
                className='w-full'
                rules={[
                  { type: 'email' },
                  {
                    required: true,
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input placeholder='Enter Email Address' className='w-full' />
              </Form.Item>
            </div>
            <div className='flex flex-col items-start justify-between mt-[14px]'>
              <h1 className='font-semibold text-[14px] mb-[6px]'>
                Phone number <span className='text-[red]'>*</span>
              </h1>
              <Form.Item
                name='phoneNumber'
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
                className='w-full'
              >
                <PhoneNumberInput value={value} onChange={handlePhoneNumberChange} />
              </Form.Item>
            </div>
            <div className='flex flex-col items-start justify-between mt-[14px]'>
              <h1 className='font-semibold text-[14px] text-[#344054] mb-[6px]'>
                Describe Your Real Estate Investing <span className='text-[red]'>*</span>
              </h1>
              <Form.Item
                name='investmentType'
                rules={[
                  {
                    required: true,
                    message: 'Please Select an Option!',
                  },
                ]}
                className='w-full'
                validateTrigger={validationTrigger?.investmentType}
              >
                <Select name='type' placeholder='Please Select an Option'>
                  {sourceOptions?.map((option) => (
                    <Option value={option.value} key={option.text}>
                      {option.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='flex flex-col items-start justify-between mt-[14px]'>
              <h1 className='font-semibold text-[14px] mb-[6px]'>How can we help? (optional)</h1>
              <Form.Item name='message' className='w-full'>
                <TextArea rows={4} placeholder='Enter your message' className='w-full' />
              </Form.Item>
            </div>
            {/* <div className='flex flex-col items-start justify-between mt-[24px]'>
              <Form.Item
                name='privacyCheck'
                valuePropName='checked'
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('Should accept agreement'),
                  },
                ]}
                className='w-full'
              >
                <Checkbox className='w-full'>
                  <span className='font-normal text-[16px]'>
                    You agree to our friendly
                    <Link to='/privacy' className='ml-[4px] underline' target='blank'>
                      privacy policy.
                    </Link>
                  </span>
                </Checkbox>
              </Form.Item>
            </div> */}
            <div className='flex flex-col items-start justify-between mt-[24px]'>
              <Form.Item className='w-full'>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isButtonDisabled}
                  className='w-full'
                  loading={loading}
                >
                  Send Your Message
                </Button>
              </Form.Item>
            </div>
          </Form>
          <button
            onClick={handleCancel}
            className={`flex justify-center items-center mx-2 w-full text-center mt-[10px] py-[4px] rounded-[6px] border-[1px] border-solid  ${loading ? 'text-[#d9d9d9] border-[#d9d9d9]' : 'text-[black] border-[black]'}`}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}
export default ContactUsModal
